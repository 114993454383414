@import 'utilities';
@import 'fonts';

.App-header {
  background: var(--brand_white);
  font-family: 'Noto Sans';
}

body {
  margin: 0;
  font-family: 'Noto Sans';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-top-bar {
  height: 72px;
  padding: 16px 32px;
  background-color: var(--brand_white);
  border-bottom: 1px solid var(--greyscale_88);

  &-container {
    padding: 0;
  }

  &.disabled {
    pointer-events: none;
  }

  .navbar-brand {
    margin-right: 26px;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    border-bottom: 0;
  }
}

.site-top-bar .site-logo img,
.site-top-bar .site-logo svg {
  height: 28px;
}

.site-top-bar .navbar-collapse {
  text-align: start;
}

.site-top-bar .navbar-toggler {
  border-color: transparent;
}

.site-top-bar .navbar-toggler:focus {
  box-shadow: unset;
}

.site-top-bar .navbar-light .navbar-nav .show > .nav-link,
.site-top-bar .navbar-light .navbar-nav .nav-link.active,
.site-top-bar .navbar-nav .nav-link.active {
  color: var(--primary_40);
  font-weight: 600;
  .profile-nav-icon {
    border: 2px solid var(--primary_40);
    svg {
      stroke: var(--primary_40);
      stroke-width: 2px;
    }
  }
  .notification-background {
    background-color: var(--primary_96);
  }
}

.site-top-bar .navbar-light .navbar-nav .nav-link:hover,
.site-top-bar .navbar-light .navbar-nav .nav-link:focus,
.site-top-bar .navbar-nav .nav-link:hover,
.site-top-bar .navbar-nav .nav-link:focus {
  color: var(--primary_40);
}

.site-top-bar .navbar-light .navbar-nav .nav-link,
.site-top-bar .navbar-nav .nav-link {
  color: var(--greyscale_40);
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  margin-right: 16px;
  padding: 0px 6px;

  &.disabled {
    pointer-events: none;
  }

  &.right-link {
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }
    &.disabled {
      pointer-events: none;
    }
  }

  .profile-nav-icon {
    position: relative;
    display: flex;
    border: 1.5px solid var(--greyscale_88);
    border-radius: 64px;
    padding: 8px;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    &.disabled {
      pointer-events: none;
    }
    &:hover {
      border-color: var(--primary_48);
      svg {
        stroke: var(--primary_48);
      }
    }

    .has-notification {
      position: absolute;
      right: 0%;
      top: 0%;
      background-color: var(--error_112);
      border: 1.5px solid var(--brand_white);
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }

    svg {
      stroke-width: 1.5;
      stroke: var(--greyscale_08);
      height: 18px;
      width: 18px;
    }

    &.align-self-center {
      &.d-block {
        &.nav-link {
          margin-left: -9px;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.site-top-bar .navbar-light .navbar-nav .profile-nav .dropdown-toggle::after,
.site-top-bar .navbar-nav .profile-nav .dropdown-toggle::after {
  border: 0;
  display: inherit;
}

.site-top-bar .navbar-nav .profile-nav .dropdown-menu {
  right: 0;
  left: unset;
  border-radius: 12px;
  border: 0;
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%),
    0px 4px 6px -2px rgb(0 0 0 / 5%);
  width: 200px;
  overflow: hidden;
  .version-info {
    font-size: 11px;
    padding-right: 12px;
    text-align: end;
    color: var(--greyscale_72);
    margin-bottom: 10px;
    .title {
      margin-right: 5px;
      color: var(--greyscale_80);
    }
  }
}

.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_56);
  padding: 8px 32px 8px 12px;
}

.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item:active,
.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item:hover,
.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item:focus {
  background-color: var(--grayscale_96);
}

.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item .icon {
  margin-right: 10px;
}

.site-top-bar .navbar-nav .profile-nav .dropdown-menu .dropdown-item .icon svg {
  height: 1rem;
  width: 1rem;
}

.site-end-links {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-cart {
    display: flex;
    border: 1px solid var(--primary_40);
    border-radius: 64px;
    padding: 10px 16px;
    height: 40px;
    width: 94px;
    align-items: center;

    &-icon {
      height: 20px !important;
    }

    &-items {
      margin: auto 8px;
    }
  }
}

.site-end-links a svg {
  height: 48px;
  width: 48px;
}

.site-top-bar .navbar-nav .dropdown-menu .dropdown-item .logged-in-user {
  padding: 12px 16px;
}

.site-top-bar {
  .profile-nav.right-link.nav-item {
    a {
      padding: 12px 0px 12px 8px;
      margin-right: 0;
    }
    .show {
      .profile-nav-icon {
        border: 2px solid var(--primary_40);
        svg {
          stroke: var(--primary_40);
          stroke-width: 2px;
        }
      }
    }
    .active {
      color: var(--primary_40);
      background-color: transparent;
    }
  }
}
.inactive-user-setting {
  pointer-events: none;
}

.loggedin-user-as {
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_56);
}

.loggedin-user-title {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_08);
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App {
  margin-bottom: 100px;
}
.app-margin-patner-invite {
  margin-top: 300px;
  text-align: center;
}

.container-top-offset {
  margin-top: 4px;

  .page-header {
    margin-bottom: 10px;
  }

  .device-tabs {
    font-size: toRem(14px);

    .section-tabs {
      .section-tab {
        display: flex;
      }
    }
  }
}

.btn {
  border-radius: 12px;
  font-weight: 600;
}

.btn-primary {
  background-color: var(--primary_40);
  border-color: var(--primary_40);
  &:hover {
    background-color: var(--primary_24);
    border-color: var(--primary_24);
  }
  &:disabled {
    background-color: var(--greyscale_88);
    border-color: var(--greyscale_88);
    color: var(--greyscale_32);
  }
  &:focus {
    background-color: var(--primary_24) !important;
    border-color: var(--primary_24) !important;
  }
  &.org-btn {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// TODO: this need to fix from frontend
// .btn-primary-outline {
//     background-color: var(--transparent_color) !important;
//     border-color: var(--primary_40) !important;
//     color: var(--primary_40) !important;
//     &:hover {
//       background-color: var(--primary_96) !important;
//       border-color: var(--primary_24) !important;
//     }
//     &:disabled {
//       background-color: var(--transparent_color) !important;
//       border-color:  var(--greyscale_72) !important;
//       color: var(--greyscale_72) !important;
//     }
// }
.signin-btn {
  font-size: toRem(18px);
  font-weight: bold;
}

.signin-btn:active,
.signin-btn:focus-visible {
  background: var(--brand_primary) !important;
  border-color: var(--brand_primary) !important;
  box-shadow: var(--brand_primary) !important;
}

/* Site Page Header */
.page-header {
  margin-bottom: 16px;
  margin-top: 24px;

  .nav {
    justify-content: end;
  }

  .page-header-buttons {
    .btn {
      width: auto;
    }
  }

  .page-title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: toRem(24px);
    line-height: 32px;
    color: var(--brand_black);
    vertical-align: middle;
    padding: 0.375rem 0.75rem;

    &.org-details {
      color: var(--brand_white);
      margin-top: 20px;
    }
    &.org-details-black {
      color: var(--brand_black);
      margin-top: 20px;
    }
  }

  .page-title-note {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: var(--grayscale_32);
    margin-bottom: 0;

    &.org-details {
      margin-top: 8px;
      font-size: 14px;
      text-transform: none;
      color: rgba(var(--greyscale-custom3), 0.8);
    }

    &.org-ids {
      margin-top: -2px;
      font-size: 14px;
      text-transform: none;
      color: rgba(var(--greyscale-custom3), 0.8);
      margin-left: 0px;
    }

    &.org-details-black {
      color: var(--brand_black);
    }
  }

  .page-title-breadcrumbs {
    color: var(--greyscale_08);
    font-family: 'Noto Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: 28px;
  }
}

/* Site Breadcrumbs */
.site-breadcrumbs .breadcrumb-item a,
.site-breadcrumbs .breadcrumb-item a:hover,
.site-breadcrumbs .breadcrumb-item a:focus {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_56);
  text-decoration: none;
}

.site-breadcrumbs .breadcrumb-item.active a,
.site-breadcrumbs .breadcrumb-item.active a:hover,
.site-breadcrumbs .breadcrumb-item.active a:focus {
  font-weight: 600;
  color: var(--greyscale_08);
}

.org-details-site-breadcrumbs .breadcrumb-item a,
.org-details-site-breadcrumbs .breadcrumb-item a:hover,
.org-details-site-breadcrumbs .breadcrumb-item a:focus {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--primary_80);
  text-decoration: none;
}

.org-details-site-breadcrumbs .breadcrumb-item.active a,
.org-details-site-breadcrumbs .breadcrumb-item.active a:hover,
.org-details-site-breadcrumbs .breadcrumb-item.active a:focus {
  font-weight: 600;
  color: var(--brand_white);
}

.org-details-site-breadcrumbs .breadcrumb {
  --bs-breadcrumb-divider-color: var(--bs-breadcrumb-divider-color);
}

.org-details-site-breadcrumbs-custom .breadcrumb-item a,
.org-details-site-breadcrumbs-custom .breadcrumb-item a:hover,
.org-details-site-breadcrumbs-custom .breadcrumb-item a:focus {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--primary_40);
  text-decoration: none;
}

.org-details-site-breadcrumbs-custom .breadcrumb-item.active a,
.org-details-site-breadcrumbs-custom .breadcrumb-item.active a:hover,
.org-details-site-breadcrumbs-custom .breadcrumb-item.active a:focus {
  font-weight: 600;
  color: var(--breadcrumb-color1);
}

.org-details-site-breadcrumbs-custom .breadcrumb {
  --bs-breadcrumb-divider-color: var(--bs-breadcrumb-divider-color);
}

.site-breadcrumbs a > .breadcrumb-divider {
  margin: 0 10px;
}

.site-breadcrumbs .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.site-breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 8px;
}

.site-breadcrumbs .svg-inline--fa {
  vertical-align: middle !important;
}

/*List blocks for the data listing */
.list-block-item.card {
  min-height: 76px;
  border-radius: 12px;
  padding: 20px;
  background-color: var(--list_block_background_color);
  margin-bottom: 25px;
  margin-top: 5px;
  border: 0;
}

.card-header {
  background-color: transparent;
  border: 0;
  padding: 5px;
  // margin-bottom: 14px;
}

/*List blocks for the licences listing */
.licences-title {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_40);
}

.licences-count {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_08);
}

// list block location address
.location-address {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: toRem(16px);
  line-height: 24px;
  color: var(--greyscale_40);
}

// empty user
.userIcon.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 44px 0px;
  gap: 8px;
}

.noUser {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_40);
}

.mainaddress {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_08);
}

.card-header {
  .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--brand_black);
    margin-bottom: 8px;
    margin-top: 36px;
  }

  .list-block-nav {
    a {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--primary_40);
    }
  }
}

.list-block-item.card > .card-header .list-block-nav-icon img,
.list-block-item.card > .card-header .list-block-nav-icon svg {
  height: 8px;
  cursor: pointer;
}

.list-block-item.card > .card-header .list-block-nav-icon svg path {
  stroke-width: 3px;
}

.list-block-item.card > .card-body {
  background-color: transparent;
  padding: 0;
  // margin-top: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--greyscale_40);
}

.list-block-item.card .card-body .row:first-child {
  margin-bottom: 24px;
}

.list-block-item.card .card-body .row:only-child {
  margin-bottom: 0;
}

/* Error block CSS*/
.site-block {
  border-radius: 8px;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
}

.site-block .site-block-content {
  padding: 0 20px;
}

.site-block .error-block-content {
  padding: 0 8px;
}

.site-block-content.default-block-content {
  padding: 0 16px;
}

.site-block {
  .site-block-content {
    .site-block-title {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 30px;
      text-transform: capitalize;
    }

    .site-block-value {
      font-style: normal;
      font-weight: 700;
      font-size: toRem(24px);
      line-height: 32px;
    }
  }
}

.error-block {
  background: var(--error_block_background_color);
}

.error-block-title {
  color: var(--greyscale_40);
}

.error-block-value {
  color: var(--error_block_value_color);
}

.warning-block {
  background: var(--warning_80);
  padding: 8px 16px;
}

.warning-block-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.warning-block-title {
  color: var(--warning_block_label_value_color);
}

.warning-block-value {
  color: var(--warning_block_label_value_color);
}

.main-content {
  text-align: left;
  display: grid;
}

/* Input css */

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background: var(--error_96);
}

.was-validated .form-control:invalid::placeholder,
.form-control.is-invalid::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--error_64);
}

.form-control {
  padding: 12px 16px;
  background-color: var(--brand_white);
  color: var(--greyscale_08);
  border: 1.5px solid var(--greyscale_88);
  height: 44px;
  border-radius: 12px;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  box-shadow: unset;
}
.autoaddress {
  padding-right: 30px !important;
}
.form-control:focus,
.form-control:active,
.form-control:hover,
.btn:active,
.btn:focus,
.btn:active:focus {
  box-shadow: unset;
  border-color: var(--brand_primary); // ToDo: Need to Fix
}

.form-control::placeholder {
  font-size: toRem(16px);
  color: var(--greyscale_56);
}

.input-with-icons {
  position: relative;

  .form-control {
    text-indent: 30px;

    .is-invalid {
      background-image: none;
    }
  }

  .was-validated {
    .form-control:invalid {
      background-image: none;
    }
  }

  .icon-before-input {
    position: absolute;
    opacity: 1;
    left: 20px;
    height: 52px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-after-input {
    position: absolute;
    right: 20px;
    opacity: 1;
    height: 52px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }

  .icon-before-input,
  .icon-after-input {
    svg {
      fill: var(--greyscale_56);

      path {
        stroke: var(--greyscale_56);
      }
    }
  }
}

.error {
  color: var(--error_64);
  margin-top: 0.25rem;
  text-align: start;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(12px);
  line-height: 16px;
}

.info-timezone {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -5px;
  margin-bottom: 10px;

  .message-info {
    margin-left: 5px;
    color: var(--body_color);
    font-size: toRem(12px);
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
  }
}

.error-message {
  color: var(--error_64);
  padding: 0.5em 0.2em;
  height: 1em;
  position: absolute;
  font-size: 0.8em;
}

/*Site Avatars*/
.site-avatars {
  .site-avatar-number {
    display: inline-block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
    color: var(--primary_40);
    background-color: var(--primary_96);
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: toRem(14px);
  }
}

.avatars-list {
  margin-bottom: 4px;

  .container {
    padding-left: 10px;
  }

  .site-avatars {
    margin-right: 1px;
    border: 1px solid #fff;
    border-radius: 100%;
    margin-top: 5px;
  }
}

.avatars-list-title {
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 32px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--greyscale_40);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--greyscale_88);
}

/* Table styles */

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table thead,
.table tbody,
.table tfoot,
.table tr,
.table td,
.table th {
  text-align: start;
  padding: 0 16px;
  height: 56px;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
}

.table {
  thead {
    height: 40px;

    tr,
    th {
      height: 40px;
    }
  }
}

thead,
.table > :not(:first-child) {
  border-top: 0;
}

.table thead td,
.table thead th {
  color: var(--greyscale_56);
  height: 40px;
}

.table tbody td {
  border-style: none solid solid none;
  color: var(--greyscale_08);
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table tbody tr {
  background-color: var(--grayscale_96);
  height: 56px;
  &:hover {
    background-color: var(--greyscale_88);
  }
  cursor: pointer;
  &.no-data-found {
    background-color: transparent;

    td {
      border-color: transparent;
      color: var(--brand_primary);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.table tbody td svg {
  width: 18px;
  height: 18px;
}

.table tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table tr:first-child td {
  border-top-style: solid;
}

.table tr td:first-child {
  border-left-style: solid;
}

.table .columnheader-asc span,
.table .columnheader-desc span {
  margin-left: 12px;
}

.table .columnheader-desc {
  color: var(--greyscale_40, #566376);
  font-weight: 600;
}

.table .columnheader-desc svg path {
  stroke: var(--greyscale_40, #566376);
}

.table .columnheader-asc {
  color: var(--greyscale_56);
}

.table .columnheader-asc svg path {
  stroke: var(--greyscale_56);
}

/* Site Spinner */
.site-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--brand_white);
  --_m: conic-gradient(#0000 2%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: s3 1s infinite linear;
  vertical-align: middle;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.row .site-spinner {
  padding-left: 5px;
  padding-right: 5px;
}

.main-card {
  background: var(--brand_white);
  border: 0;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
}

.row .site-spinner {
  padding-left: 5px;
  padding-right: 5px;
}

.main-card {
  background: var(--brand_white);
  border: 0;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
}

.account-verify {
  background-color: var(--primary_96);
}

.account-verify .site-top-bar {
  margin-bottom: 0;
}

.account-verify .invitationCodeOtp {
  justify-content: center;
}

.account-verify-input-section {
  border-bottom: 1px solid var(--greyscale_88);
  padding-bottom: 40px;
}

.account-verify .form-control {
  margin-bottom: 16px;
}

a {
  color: var(--primary_40);
  font-style: normal;
  font-weight: 600;
  font-size: toRem(14px);
  line-height: 20px;
  text-decoration: underline;
}

.footer a {
  color: var(--primary_40);
  font-style: normal;
  font-weight: 600;
  font-size: toRem(14px);
  line-height: 20px;
  text-decoration: none;
  margin-right: 16px;
}

.help-link {
  color: var(--primary_64);
}

.invitationCodeOtp input {
  min-width: 64px;
  height: 72px;
}

/*Placeholder wrapper*/
.placeholder-wrapper {
  font-size: toRem(14px);
  color: var(--greyscale_56);
  top: 0;
  left: 16px;
  height: 44px;
  padding: 12px 16px;
  position: absolute;
  pointer-events: none;

  &.org-invite {
    top: 45px;
    left: 45px;
  }
  &.org-invite-partner{
    top: 77px !important;
    left: 36px !important;
  }
}

.placeholder-wrapper.required:after {
  content: '*';
  margin-left: 6px;
  color: var(--error_64);
}

.placeholder-wrapper.is-invalid {
  color: var(--error_64);
}

.form-control:focus + .placeholder-wrapper,
.form-control:active + .placeholder-wrapper,
.form-control.has-value + .placeholder-wrapper {
  display: none;
}

.input-with-icons .placeholder-wrapper {
  left: 50px;
}

.toast-container {
  width: 95%;

  .toast {
    width: 100%;
    min-height: 92px;
    border: 0;
    border-left: 8px solid;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-color: var(--error_64);

    .toast-header {
      border-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: toRem(16px);
      line-height: 20px;
      color: var(--greyscale_08);

      svg {
        height: 15px;
        width: 15px;
        margin-right: 10px;
      }
    }

    .toast-body {
      padding-left: 35px;
      padding-top: 0;
      color: var(--greyscale_56);
      font-style: normal;
      font-weight: 400;
      font-size: toRem(14px);
      line-height: 20px;
      text-align: left;
    }

    &.error-toast {
      .toast-header {
        svg path {
          fill: var(--brand_white);
          stroke: var(--error_64);
        }
      }
    }

    &.success-toast {
      border-color: var(--success_48);

      .toast-header {
        svg path {
          fill: var(--success_48);
          stroke: var(--brand_white);
        }
      }
    }
  }
}

.modal-wrapper {
  .toast-container {
    width: 300px;
    right: 15px;
    top: 10px;
  }
}

.resend-code-link {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_56);
}

.resend-code-link .site-spinner {
  padding: 2px;
}

.no-underline {
  text-decoration: none;
  color: var(--primary_40) !important;
  font-weight: 600 !important;
  font-size: toRem(14px) !important;
  margin-right: 20px !important;
}

.small-decimals {
  font-size: 1.125rem;
  margin-left: 2px;
}

//Dashboard user flow
span.userName {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.table tbody td .activeIcon svg {
  height: 10px;
  width: 10px;
}

// add users administator input filed
.administatorWrap {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;

  .checkWrap {
    display: flex;
    justify-content: space-between;
    padding: 0px;

    .addmelabel {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: var(--primary_40);
      }

      &:focus + .slider {
        box-shadow: 0 0 1px var(--primary_40);
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--greyscale_88);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      top: 2px;
      background-color: var(--brand_white);
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

// Assigned Technicians
.selectField input[type='checkbox'] {
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectField input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--brand_white);
  text-align: center;
  position: absolute;
}

.selectField input[type='checkbox']:checked::after {
  position: absolute;
  width: 16px;
  height: 16px;
  background: var(--brand_primary);
  color: var(--brand_white);
  content: '\2713';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--brand_white);
  text-align: center;
  position: absolute;
}

input[type='checkbox']:checked::after {
  position: absolute;
  width: inherit;
  height: inherit;
  background: var(--brand_primary);
  color: var(--brand_white);
  content: '\2713';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.site-block {
  .site-block-content.trouble {
    text-decoration-line: underline;
    color: var(--primary_08);

    .site-block-title {
      font-weight: 600;
      color: var(--primary_08);
      font-size: toRem(14px);
      line-height: 20px;
    }
  }
}

// Table actions dropdown
.table-action-dropdown {
  &:focus-visible {
    outline: unset;
  }

  .dropdown-item {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--greyscale_40);
    padding: 8px 32px 8px 12px;

    .active,
    &:active,
    &:focus,
    &:hover {
      background-color: var(--grayscale_96);
    }
  }

  .dropdown-menu {
    box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%),
      0px 4px 6px -2px rgb(0 0 0 / 5%);
    border: 0;
    border-radius: 12px;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.activeIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 2px;

  svg {
    height: 10px;
    width: 10px;
  }

  .status-icon-active {
    fill: var(--success_48);
    margin-right: 5px;
  }

  .status-icon-invited {
    fill: var(--warning_80);
    margin-right: 5px;
  }

  .status-icon-inactive {
    fill: var(--warning_64);
    margin-right: 5px;
  }

  .status-icon-notactive {
    fill: var(--greyscale_40);
    margin-right: 5px;
  }

  .status-icon-disabled {
    fill: var(--greyscale_40);
    margin-right: 5px;
  }
}

.assign-location-wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background: var(--grayscale_96);
  border-radius: 12px;
  margin-top: 8px;
  font-size: 14px;

  .check-location-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    .check-location-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        text-align: center;
        height: 28px;
        width: 28px;
        background: var(--brand_white);
        border-radius: 4px;
        margin-right: 8px;
      }
    }
  }

  .check-location-btn {
    cursor: pointer;
  }
}

.locations-area-list-wrap {
  margin-bottom: 25px;

  .locations-list-wrap {
    margin-top: 24px;

    &:first-child {
      margin-top: 8px;
    }

    .location-label {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(16px);
      line-height: 20px;
      color: var(--greyscale_08);
      margin-bottom: 16px;
    }

    .location-areas-list {
      .location-areas-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background: var(--grayscale_96);
        border-radius: 12px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .area-form-check-label {
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
        }

        .area-form-check-input {
          height: 20px;
          width: 20px;
        }

        input[type='checkbox'] {
          border: 5px solid red;
        }
      }
    }
  }
}

.site-back-button {
  font-size: toRem(16px);
  line-height: 20px;
  font-weight: 500;

  svg {
    margin-right: 7px;
  }
}

.no-padding {
  padding: 0;
}

.plus-icon-btn {
  margin-right: 10px;
}

.tab-title {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--greyscale_08);
  margin-bottom: 8px;
  margin-top: 36px;

  &.focus-tab {
    margin-bottom: 5px;
  }
}

.tab-sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(12px);
  color: var(--brand_primary);
  line-height: 16px;
}

.toggle-field-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;

  &.toggle-bg {
    background: transparent;
    padding: 5px;
    flex-direction: row;
  }

  .toggle-field-container {
    display: flex;
    justify-content: space-between;
    padding: 0px;

    &.add-edit-item {
      margin-right: 10px;
    }

    .toggle-field-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;

      &.firmware-label {
        color: var(--text_color);
      }
      &.kbps {
        color: var(--brand_black);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
      }
    }
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-top: 5px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .toggle-slider {
        background-color: var(--primary_40);
      }

      &:focus + .toggle-slider {
        box-shadow: 0 0 1px var(--primary_40);
      }

      &:checked + .toggle-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--greyscale_88);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      top: 2px;
      background-color: var(--brand_white);
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

.select-field-wrap {
  margin-top: 16px;
  margin-bottom: 16px;

  .form-select {
    background-image: url('../../assets/images/icons/DropdownArrow.svg');
  }
}

.range-field-wrap {
  background-color: var(--grayscale_96);
  border-radius: 12px;
  padding: 20px;
  gap: 8px;

  &.org-details {
    background-color: transparent;
    padding: 5px;
    pointer-events: none;
    margin-right: 10px;
  }

  &.no-devices {
    background-color: transparent;
    padding: 5px;
    pointer-events: none;
    margin-left: 15px;
    margin-right: 10px;
  }

  .range-field-label {
    margin-bottom: 8px;
  }

  .range-slider {
    min-height: 34px;
    max-width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(12px);
    line-height: 16px;

    .range-slider-track {
      top: 25px;
      height: 8px;
      background: var(--greyscale_88);
      border-radius: 12px;

      &.range-slider-track-0 {
        /* color of the track before the thumb */
        background: var(--primary_40);
      }

      .range-slider-start-value {
        position: absolute;
        bottom: 18px;
      }

      .range-slider-last-value {
        position: absolute;
        right: 0;
        bottom: 18px;
      }
    }

    .org-range-slider-track {
      top: 25px;
      height: 8px;
      background: var(--progress_org_device);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-left: -10px;

      &.org-range-slider-track-0 {
        /* color of the track before the thumb */
        background: var(--primary_40);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 10px;
      }

      .range-slider-start-value {
        position: absolute;
        bottom: 18px;
      }

      .range-slider-last-value {
        position: absolute;
        right: 0;
        bottom: 18px;
      }
    }

    .range-slider-thumb {
      top: 15px;
      width: 24px;
      height: 24px;
      background: var(--primary_40);
      border: 8px solid var(--brand_white);
      border-radius: 50%;
      box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.06),
        0px 2px 4px -1px rgba(var(--greyscale-custom1), 0.04);

      &:focus-visible {
        outline: unset;
      }

      .range-slider-thumb-value {
        position: absolute;
        bottom: 18px;
        left: -5px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: 16px;
        color: var(--primary_40);
      }
    }
    .range-slider-mark {
      color: var(--greyscale_40);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      top: 45px;
      padding: 0px 5px;
    }
  }
  .custom-radius {
    .org-range-slider-track {
      margin-left: 1px;
    }
  }
}


.grouped-settings-block {
  div {
    &:first-child {
      & > * {
        border-radius: 12px 12px 0 0;
      }
    }

    &:last-child {
      & > * {
        border-radius: 0 0 12px 12px;
        padding-top: 0;

        .range-field-label {
          color: var(--greyscale_40);
          font-weight: 500;
          font-size: toRem(12px);
          line-height: 16px;
        }
      }
    }
  }
}

.table-responsive {
  overflow-x: visible;

  @media screen and (max-width: 767px) {
    overflow-x: auto;
  }
}

.site-no-content-block {
  margin-top: 50px;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 44px 0px;
  gap: 8px;
  background: var(--grayscale_96);
  border-radius: 12px;

  .no-content-block {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: 20px;
    color: var(--greyscale_56);
    margin-top: 26px;
  }
}

.noUser-block {
  .list-block-item.card {
    padding: 0;
  }
}

.loader-wrapper-session {
  &.white-background {
    background: rgba(var(--greyscale-custom3), 0.7);

    .lds-ring {
      div {
        border: 3px solid var(--brand_black);
        border-color: rgba(var(--greyscale-custom1), 0.9) transparent
          transparent transparent;
      }
    }
  }

  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(var(--greyscale-custom3), 0.2);

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 72px;
      height: 72px;
      margin: 8px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: blue transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.status-icon-online {
  fill: var(--success_48);
  margin-right: 6px;
}

.status-icon-offline {
  fill: var(--error_48);
  margin-right: 6px;
}

.status-icon-entered {
  fill: var(--primary_40);
  margin-right: 6px;
}
.remove-org-modal {
  .modal-dialog .modal-content {
    min-width: 24.375rem !important;
    width: 24.375rem !important;
  }

  .modal-dialog .modal-content .modal-header .modal-title {
    text-align: center;
    width: 100%;
  }
}
.manage-location-main {
  .create-area-list {
    svg {
      margin-left: 10px;
    }
    .create-area-icon {
      stroke: var(--primary_40);
    }
    .remove-area-icon {
      stroke: var(--error_64);
    }
    .remove-default-area-icon {
      stroke: var(--greyscale_56);
    }
  }
  .location-modal-heading {
    color: var(--greyscale_08);
    font-family: 'Noto Sans';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
  .ml-2 {
    margin-left: 1rem;
  }
}
.manage-pos-setup{
  .modal-dialog .modal-content {
    padding: 32px 32px 20px 32px;
    min-height: auto;

    .modal-body{
      max-height: 100vh;
    }
  }
  .manage-pos-height {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px; // for vertical scroll bar
    }
  }
}

.unlock-success-modal {
  .modal-dialog .modal-content {
    min-width: 24.375rem !important;
    width: 24.375rem !important;
  }

  .modal-dialog .modal-content .modal-header .modal-title {
    text-align: center;
    width: 100%;
  }
}

.custom-role-view-modal {
  .modal-dialog .modal-content .modal-header {
    padding-bottom: 0.25rem;
  }
}

.grey-background {
  background: var(--grayscale_96);
  border-radius: 12px;
  padding-bottom: 4px;
}

.customized-datepicker.rmdp-wrapper {
  .rmdp-calendar {
    .rmdp-day-picker {
      > div {
        .rmdp-week {
          .rmdp-range {
            box-shadow: none;
            background-color: var(--brand_white) !important;
            > span {
              background-color: var(--brand_primary);
            }
          }
          .rmdp-today {
            > span {
              background-color: var(--brand_primary);
            }
          }
          .rmdp-day {
            > span {
              &:hover {
                background-color: var(--brand_primary);
              }
            }
          }
          .rmdp-day.rmdp-disabled {
            > span {
              &:hover {
                background-color: var(--brand_white) !important;
                color: var(--greyscale_40);
              }
            }
          }
          .rmdp-day.mdp-today.rmdp-selected {
            color: var(--brand_white);
          }
          .rmdp-week-day {
            color: var(--greyscale_40);
          }
          .rmdp-selected {
            > span {
              &:not(.highlight) {
                background-color: var(--brand_primary);
              }
            }
          }
        }
      }
    }
    div {
      .rmdp-day-picker {
        .rmdp-week {
          .rmdp-day.rmdp-today {
            span {
              color: var(--brand_black);
              background-color: var(--brand_white);
              border: 1px solid var(--brand_primary) !important;
            }
          }
        }
      }
      .rmdp-month-picker {
        .rmdp-day.rmdp-selected span:not(.highlight) {
          background-color: var(--brand_primary) !important;
        }
        .rmdp-day.rmdp-selected span:not(.highlight) {
          &:hover {
            background-color: var(--brand_primary) !important;
          }
        }
        .rmdp-day span {
          &:hover {
            background-color: var(--brand_primary) !important;
            opacity: 0.5 !important;
          }
        }
        .rmdp-day.rmdp-disabled span {
          &:hover {
            background-color: var(--brand_white) !important;
          }
        }
        rmdp-day.rmdp-today span {
          background-color: var(--brand_primary) !important;
          color: var(--brand_white) !important;
        }
      }
      .rmdp-year-picker {
        .rmdp-day.rmdp-selected span:not(.highlight) {
          background-color: var(--brand_primary) !important;
        }
      }
    }
    .rmdp-header {
      color: var(--brand_black);
      padding-left: 12px;
      padding-right: 12px;

      .rdmp-arrow-container {
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 25px;
        width: 25px;

        .rmdp-arrow {
          border-color: var(--brand_primary);
          margin-bottom: 4px;
          cursor: pointer;
          height: 10px;
          width: 10px;
        }

        &:hover {
          box-shadow: none;
          background-color: var(--brand_primary);
          .rmdp-arrow {
            border-color: var(--brand_white) !important;
            color: var(--brand_white) !important;
          }
        }
        &.disabled {
          .rmdp-arrow {
            border-color: var(--greyscale_40) !important;
            color: var(--greyscale_40);
            cursor: auto;
            pointer-events: none;
          }
        }

        &.active {
          &:hover {
            border-radius: 50%;

            .rmdp-arrow {
              color: var(--brand_white) !important;
            }
          }
        }
      }

      .rmdp-header-values {
        color: var(--brand_primary);

        &:hover {
          color: var(--primary_32);
        }

        span {
          font-size: toRem(16px);
          font-weight: 600;
          line-height: toRem(20px);
        }
      }

      .disabled {
        .rmdp-arrow {
          border-color: var(--greyscale_40) !important;
          color: var(--greyscale_40);
        }
        &:hover {
          border-color: var(--greyscale_40) !important;
          background-color: var(--brand_white) !important;

          .rmdp-arrow {
            border-color: var(--greyscale_40) !important;
          }
        }
      }

      + div {
        .rmdp-day-picker,
        .rmdp-month-picker,
        .rmdp-year-picker {
          width: 100%;
          > div {
            width: 100%;
          }
        }
      }
    }
  }
  .rmdp-action-buttons {
    .rmdp-button {
      color: var(--brand_primary);
    }
  }
}

.incident-details-date-wrapper {
  .rmdp-header {
    color: var(--brand_black);
    padding-left: 8px !important;
    padding-right: 8px !important;

    .rmdp-arrow-container {
      height: 22px;
      width: 22px;

      .rmdp-arrow {
        height: 10px;
        width: 10px;
      }
    }
  }
}

.reset-address-wrapper {
  color: var(--greyscale_08);
  top: -2px;
  height: 52px;
  padding: 16px;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  .autoaddress {
    padding-right: 30px !important;
  }
}

.address-with-reset .reset-address-wrapper {
  right: 10px;
}
.modal-form {
  .error {
    margin-bottom: 8px;
  }
}

.account-status-modal {
  .modal-dialog .modal-content {
    width: 629px;
    min-width: 629px;
    margin-left: -50px;
    padding: 40px;
    .modal-body {
      padding-bottom: 0;

      .account-status-modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .account-status-modal-title {
        color: var(--greyscale_08);
        font-size: toRem(24px);
        line-height: 32px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 8px;
        margin-top: 24px;
      }
      .account-status-modal-description {
        color: var(--greyscale_56);
        font-size: toRem(14px);
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 24px;
      }
      .site-modal-close {
        cursor: pointer;
        color: var(--greyscale_40);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }
    }
  }
}

// This selector affects all Material UI radio buttons in the app
.MuiFormGroup-root .MuiRadio-root {
  &.Mui-checked {
    & > span,
    .MuiTouchRipple-root {
      color: var(--primary_40);
    }
  }
}

.settings-block-share_recipients {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  background: var(--grayscale_96);
  border-radius: 12px;
  margin-top: 9px;
  .add-share-icon {
    margin-left: 15px;
    color: var(--greyscale_08);
  }
  .toggle-field-label {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    margin-left: -15px !important;
  }
}
.toggledisable {
  .toggle-field-wrap .toggle-switch input + .toggle-slider {
    background-color: var(--greyscale_64);
    &::before {
      background-color: var(--greyscale_72);
      fill: var(--greyscale_72);
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
    }
  }
}
.custom-timepicker {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background: pink;
}
.rmdp-container {
  &:nth-child(2) {
    width: 98% !important;
  }
  position: relative;
  div {
  }
  div {
    .rmdp-time-picker.bottom {
      background: var(--brand_white);
      min-width: 310px !important;
      border-radius: 10px;
      margin-left: 7px;
      border: 0.5px solid var(--greyscale_88);
      border-radius: 12px;
      @media only screen and (max-width: 767px) {
        margin-left: 6px;
      }

      /* For tablet devices (768px to 1023px width) */
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        margin-left: 6px;
      }
    }
  }
}

.rmdp-arrow-container {
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:hover {
    background-color: var(--brand_primary) !important;
    .rmdp-arrow {
      border-color: var(--brand_white) !important;
    }
  }

  .rmdp-arrow {
    margin-top: 0;
  }
}
.rmdp-arrow {
  border-color: var(--brand_primary) !important;
}

.MuiButtonBase-root.Mui-selected {
  background: var(--brand_primary) !important;
}
.MuiPickersYear-yearButton.Mui-selected {
  background: var(--brand_primary) !important;
}
.storage-text-dis {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.storge-clip-title {
  color: var(--brand_white);
}

.storge-clip-title-light {
  color: var(--greyscale_64);
}
.storge-clip-title-light40 {
  color: var(--greyscale_40);
}
.storage-error {
  color: var(--error_64);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.storage-av {
  color: var(--greyscale_64);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.m-l-15 {
  margin-left: 15px;
}
.bs-tooltip-top {
  z-index: 9999999 !important;
}
.m-t-20 {
  margin-top: 20px;
}
.MuiFormGroup-root .MuiRadio-root {
  padding: 0px;
  &.Mui-checked {
    color: var(--primary_40);
    & > span,
    .MuiTouchRipple-root {
      color: var(--primary_40);
    }
  }
}

.active-license-holder {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--success_96);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .active-lic-text {
    color: var(--success_24);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .info-text {
    color: var(--success-24);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .text-holder {
    display: inline-grid;
    text-align: left;
  }
}
.text-left {
  text-align: left;
}
.m-t-8 {
  margin-top: 8px;
}
.days-style {
  .day-text {
    color: var(--greyscale_40);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}


#popover-basic {
  border-radius: 20px;
  margin-left: -38px;
  padding: 14px 25px 16px 25px;
  border: 1px solid var(--hGreyscale_08);
  gap: 7px;
  .popover-arrow {
    margin-left: 38px;
  }
  .popover-body {
    padding-bottom: 4px;
  }
}

.profile-nav-icon-2{
  position: relative;
    display: flex;
    border-radius: 64px;
    padding: 2px;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: var(--brand_primary);
    }
  a svg {
    height: 20px;
    width: 20px;
  }
}

.appIconImgHeight {
  height: 65px;
  padding: 3px;
}

.helpStyle {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  color: var(--brand_black);
  display: flex;
  justify-content: space-between;
}

.dropdown-divider{
  height: 1px;
  opacity: 1;
  background: var(--hGreyscale_64);
  margin-top: 20px;
  margin-bottom: 10px;
}

@import './modals';
@import './mediaQueries';
