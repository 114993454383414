.grid-str-container {
  display: grid;
  gap: 10px; /* Adjust as needed */
  max-height: calc(100vh - 310px); /* Adjust as needed */
  margin: auto;
  max-width: -webkit-fill-available;
}

.camera-tile-container {
  height: 100%;
  margin: auto;
  position: relative;

  & .loading-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.device-wall-main-container {
  .main-content-container {
    max-height: calc(100vh - 310px); /* Adjust as needed */
    height: calc(100vh - 310px) !important; /* Adjust as needed */
  }
  .muuri {
    height: 100% !important;
  }
}

.item-grid {
  overflow: hidden;
  font-size: 12px;
  border: 1px solid var(--grayscale_32) !important;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 16/9;
  padding-top: 0px !important;
  height: initial !important;

  &.dragged-item {
    z-index: 2;
  }

  & video,
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  & canvas {
    position: absolute;
  }
  &.add-hover {
    & .hovered {
      display: block;
    }
  }
  & .device-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(var(--greyscale-custom2), 0.8);
    z-index: 2;
    display: none;

    // &.hovered {
    //   display: block;
    // }

    & .device-title-container {
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: left;
      font-size: 0.75rem;
      line-height: 1rem;
      padding: 0.5rem;

      & .device-name {
        font-weight: 600;
      }
      & .device-location {
        font-weight: 400;
        color: var(--greyscale_72);
      }
    }

    &.name-option-container {
      z-index: 1;
      display: block;
      background: var(--greyscale_08);

      & .device-title-container {
        & .device-name {
          color: var(--greyscale_40);
        }
        & .device-location {
          color: var(--greyscale_40);
        }
      }
    }
    & .date-time-wrapper {
      position: absolute;
      right: 32px;
      top: 8px;
      .date-time {
        display: flex;
        padding: 2px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(var(--greyscale-custom10));
        backdrop-filter: blur(5px);
        color: var(--brand_white);
        font-family: 'Noto Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
      .off-date-time {
        display: flex;
        padding: 2px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(var(--greyscale-custom10));
        backdrop-filter: blur(5px);
        color: var(--greyscale_56);
        font-family: 'Noto Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }

    & .menu-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px;
      cursor: pointer;

      & .devices-dropdown {
        & .dropdown-menu {
          background: var(--grayscale_32);
        }
        .dropdown-toggle::after {
          display: none;
        }

        &-options {
          display: flex;
          &:active {
            background-color: transparent;
          }
          &:hover {
            background-color: transparent;
          }
          svg {
            stroke: var(--greyscale_64);
          }
          &-label {
            margin-left: 8px;
            color: var(--brand_white);
            line-height: 1.25rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  & .primary-circle {
    position: absolute;
    left: 8px;
    top: 8px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    border: 1px solid var(--brand_white);
    background-color: var(--primary_64);
    z-index: 2;
  }

  & .not-active-camera {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: none !important;

    &.not-active-camera-bg {
      background: var(--greyscale_08);
    }

    & .not-active-camera-details {
      position: absolute;
      z-index: 1;

      & .no-active-icon {
        stroke: var(--greyscale_40) !important;
      }

      & .not-active-camera-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--greyscale_40);
      }

      & .not-active-camera-button {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: var(--primary_02);
        margin-top: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      & .try-again-button {
        border: 1.5px solid var(--greyscale_40);
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        width: fit-content;
        margin: auto;
        margin-top: 1rem;
        color: var(--greyscale_40);
        cursor: pointer;
      }

      & .simple-label {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-top: 0.5rem;
        color: var(--greyscale_40);
      }

      & .simple-label-2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: var(--greyscale_40);
      }
    }
  }
  & .not-active-camera-details {
    position: absolute;
    z-index: 1;

    & .no-active-icon {
      stroke: var(--greyscale_40) !important;
    }

    & .not-active-camera-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--greyscale_40);
    }

    & .not-active-camera-button {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: var(--primary_02);
      margin-top: 4px;
      text-decoration: underline;
      cursor: pointer;
    }

    & .try-again-button {
      border: 1.5px solid var(--greyscale_40);
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      color: var(--greyscale_40);
      cursor: pointer;
    }

    & .simple-label {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 0.5rem;
      color: var(--greyscale_40);
    }

    & .simple-label-2 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: var(--greyscale_40);
    }
  }
  & .streaming-container,
  .main-snapshot {
    position: absolute;
    z-index: 1;
    height: -webkit-fill-available;
  }

  & .no-video-content {
    position: absolute;
  }

  & .hide-image {
    opacity: 0;
  }
}

.item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px;
  text-align: center;
}
