@import '../../../assets/css/base';

.event-preview-modal {
  .modal-dialog {
    .modal-content {
      background: transparent;
      border-radius: 16px;
      padding: 60px;
      .modal-header {
        position: absolute;
        right: 75px;
        z-index: 1;
        .site-modal-close {
          color: #fff;
          width: 44px;
          height: 44px;
          padding: 14px;
          background: rgba(var(--greyscale-custom1), 0.4);
          backdrop-filter: blur(5px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 12px;
        }
      }
      .modal-body {
        position: initial;
        padding-bottom: 0;
        .wrapper-app {
          margin-top: 0;
        }
        .events-icons-wrapper {
          position: absolute;
          z-index: 1;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          display: flex;
          top: 50%;
          margin-top: -24px;
          left: 0;
          .icons-image {
            width: 48px;
            height: 48px;
            background: var(--primary_40);
            color: #fff;
            backdrop-filter: blur(5px);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.disabled {
              pointer-events: none;
              background: #aaaaaa;
            }
          }
        }
        .search-events-modal-footer {
          color: #fff;
          margin-top: 16px;
          .modal-footer-content {
            display: flex;
            justify-content: space-between;
            .footer-modal-description {
              .footer-modal-description-location {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
              }
              .footer-modal-description-class {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                opacity: 0.6;
                margin-top: 4px;
                display: flex;
                align-items: center;
                .event-name {
                  display: inline-block;
                  margin-left: 5px;
                }
              }
            }
            .footer-modal-redirection {
              display: flex;
              min-width: 140px;
              justify-content: space-between;
              svg {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
