@import '../../assets/css/base';

.events {
  position: relative;

  .dark-theme-icon {
    cursor: pointer;
    fill: var(--brand_white);
    filter: invert(100%);
  }

  .search-icon {
    color: var(--brand_white);
    height: 22px;
    width: 22px;
    margin-right: 6px;
  }

  .search-icon-license {
    color: var(--brand_white);
    height: 22px;
    width: 22px;
    top: 24px;
    right: -15px;
    position: absolute;
  }

  .search-icon-parent-area {
    position: relative;
  }

  .main-wrapper-events {
    background-color: var(--greyscale_08);
    height: calc(100vh - 52px);
    overflow: hidden;
    position: absolute;
    z-index: 10;
  }

  .all-events-container {
    color: var(--primary_40);
    padding-left: 24px;
  }

  .loading-text {
    color: var(--brand_white);
  }

  .seprator-tags {
    width: 100%;
    height: 2px;
    background-color: var(--greyscale_88);
  }

  .search-dashboard-allevents {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-top: -35px;
    margin-bottom: 5px;
    align-items: center;

    .serach-box {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      padding: 18px 20px;
      width: 100%;
      height: 50px;
      background: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      align-items: center;

      &.search-modal {
        width: 100%;
        margin-top: -30px;
      }

      .textbox {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(16px);
        line-height: 20px;
        color: var(--greyscale_40);
        margin-left: 10px;
        text-align: left;

        &.serach-text {
          color: var(--greyscale_08);
        }
      }
    }

    .voice-conatiner-all-result {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .outer-view {
        height: 65px;
        width: 65px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: transparent;
        align-items: center;

        &.voice-listening {
          background: var(--primary_96);
          animation: mymove 1s infinite;
        }

        @keyframes mymove {
          from {
            background-color: var(--primary_88);
          }

          to {
            background-color: var(--primary_96);
          }
        }

        .mic-btn {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          height: 50px;
          width: 50px;
          background: var(--primary_40);
          border-radius: 12px;
        }
      }
    }
  }

  .search-filter-category {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 18px;
    padding-left: 12px;

    .custom-div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      width: 238px;
      height: 60px;
      gap: 12px;
      cursor: default;

      .all-result-text {
        font-style: normal;
        display: flex;
        align-items: center;
        color: var(--brand_white);
        font-size: toRem(20px);
        font-weight: 700;
        line-height: toRem(28px);
        letter-spacing: 0em;
        text-wrap: nowrap;

        .all-events-search-icon {
          padding-right: 4px;
        }
      }
      .event-count {
        margin-left: 10px;
        font-size: toRem(14px);
        font-weight: 500;
        line-height: toRem(20px);
        letter-spacing: 0em;
        text-align: left;
        color: var(--greyscale_64);
      }
    }

    .left-conatainer-events {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;

      .all-result-text {
        font-weight: 700;
        font-size: toRem(24px);
        line-height: 32px;
        display: flex;
        align-items: center;
        color: var(--grayscale_16);
      }
      .event-count {
        margin-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        color: var(--greyscale_40);
      }
    }

    .right-filter-box {
      width: 82%;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;

      .time-box {
        display: flex;
        justify-content: flex-end;
        position: relative;
        box-sizing: border-box;
        padding: 5px;
        gap: 16px;
        border-radius: 12px;
        flex-shrink: 1;

        .search-event-date {
          position: relative;
          grid-area: 1 / 1;
          display: flex;
          width: 272px;
          padding: 12px 16px;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          background-color: var(--hGrayscale_24);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;

          &:hover {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .search-event-date-active {
          grid-area: 1 / 1;
          display: flex;
          width: 272px;
          padding: 12px 16px;
          justify-content: flex-start;
          align-items: centert;
          gap: 8px;
          background-color: var(--hGrayscale_24);
          outline: 1.5px solid var(--brand_white);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;

          &:hover {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .search-event-time {
          grid-area: 1 / 2;
          display: flex;
          width: 272px;
          padding: 12px 16px;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          background-color: var(--hGrayscale_24);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;

          &:hover {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .search-event-time-active {
          position: relative;
          grid-area: 1 / 2;
          display: flex;
          width: 272px;
          padding: 12px 16px;
          justify-content: flex-start;
          align-items: centert;
          gap: 8px;
          background-color: var(--hGrayscale_24);
          outline: 1.5px solid var(--brand_white);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;

          &:hover {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .search-ai-duclo {
          grid-area: 1 / 3;
          display: flex;
          align-items: center;
          text-align: justify;
          width: 212px;
          background-color: var(--hGrayscale_24);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;
          margin-top: -7px;
          &:hover {
            background-color: var(--primary_96);
            transition: none !important;
            box-shadow: none !important;
          }
          &:focus {
            border: 2px solid var(--brand_primary) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .search-ai-oncloud {
          grid-area: 1 / 3;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: justify;
          width: 230px;
          padding: 12px 16px;
          color: var(--primary_08);
          background-color: var(--hGrayscale_24);
          border-radius: 12px;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          height: 60px;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06),
            0px 2px 4px -1px rgba(0, 0, 0, 0.04);

          &:hover {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: 1.5px solid var(--brand_white) !important;
            transition: none !important;
            box-shadow: none !important;
          }
        }

        .settings-block-events {
          width: 439px;
        }

        .form-select {
          background-color: var(--brand_white);
          border: 1px solid var(--greyscale_88);
          border-radius: 12px;
          color: var(--primary_08);
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;

          .options {
            background-color: brown !important;
            padding: 15px !important;
          }
          &:hover {
            border: 2px solid var(--primary_48);
            transition: none;
            box-shadow: none;
          }
          &:focus {
            border: 2px solid var(--primary_48);
            transition: none;
            box-shadow: none;
          }
        }

        .left-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .label-time {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(14px);
            line-height: 20px;
            color: var(--greyscale_40);
          }
        }
      }
    }
  }

  .all-events-container {
    width: 100%;
    height: calc(100vh - 200px);

    .no-data-container {
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 43%;
      left: 19%;
      width: 100%;
      .no-data-content{
        transform: translate(-20%, 4%);
      }

      .no-result {
        margin-top: 32px;
        font-weight: 600;
        font-size: toRem(18px);
        line-height: 24px;
        text-align: center;
        color: var(--brand_white);
      }

      .message-no-result {
        margin-top: 19px;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        text-align: center;
        color: var(--greyscale_64);
      }

      .all-event-back-conatiner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .click-here {
          font-style: normal;
          font-weight: bold;
          font-size: toRem(14px);
          line-height: 20px;
          text-align: center;
          color: var(--primary_40);
          cursor: pointer;
        }

        .to-see-all-events {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
          text-align: center;
          color: var(--greyscale_40);
        }
      }
    }

    .fixed-icons-wrapper {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      display: flex;

      .filter-image,
      .filterDate-image {
        cursor: pointer;
      }

      .filterDate-image {
        margin-right: 8px;
      }
    }

    .events-all-category-wrapper {
      border-radius: 12px;
      text-align: left;
      margin-bottom: 16px;

      .event-wrapper-list {
        display: flex;
        padding: 12px;
        flex-direction: column;
        cursor: pointer;

        .event-list-image-area {
          max-width: 100%;
          box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
          border-radius: 8px;
          overflow: hidden;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          position: relative;
          z-index: -1;

          img {
            max-width: 100%;
          }

          .event-image-time {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 4px 8px;
            position: absolute;
            right: 12px;
            top: 12px;

            background: rgba(var(--greyscale-custom1), 0.4);
            backdrop-filter: blur(5px);
            /* Note: backdrop-filter has minimal browser support */

            border-radius: 8px;
            font-weight: 600;
            font-size: toRem(12px);
            line-height: toRem(16px);
            color: var(--brand_white);

            svg {
              margin-right: 5px;
            }
          }
        }

        .event-list-content {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 0px 0px 8px 8px;
          background: var(--grayscale_24);

          min-height: 60px;
          border: 0;

          .event-timestamp {
            color: var(--greyscale_64);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
          }

          .event-content-list-wrapper {
            margin-left: 0;
          }

          .event-name {
            color: var(--brand_white);
            font-size: toRem(14px);
            font-weight: 600;
            line-height: toRem(20px);
            font-weight: 600;
            text-transform: capitalize;
          }

          .event-image {
            padding: 0;
            display: flex;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
          }
        }
      }
    }

    .sticky-header {
      .header {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 16px;
        color: var(--greyscale_40);
      }
    }

    .scroll-container {
      height: calc(100vh - 165px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

  .date-picker-events {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background-color: var(--hGrayscale_24);
    border-radius: 16px;
    height: 440px;
    width: 338px;
    left: 0px;
    top: 72px;
    position: absolute;
    z-index: 99999;

    .btn-primary {
      width: 290px;
      border-radius: 10px;
    }
  }

  .time-picker-events {
    position: absolute;
    z-index: 99999;
    margin-right: 883px;
    margin-top: 316px;
    background-color: var(--hGrayscale_24);
    left: 0px;
    top: -248px;
    display: flex;
    width: 320px;
    padding: 24px 24px 32px 24px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 16px;
    width: 320px;
    height: 264px;
    border-radius: 16px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);

    .date-time-picker {
      padding: 0 !important;

      .range-field-wrap-event-time {
        background: transparent;
        .range-slider .range-slider-track {
          height: 4px;
        }
      }
    }
  }

  .time-range-label-style {
    font-size: toRem(16px);
    font-weight: 600;
    line-height: toRem(20px);
    letter-spacing: 0em;
    text-align: left;
    color: var(--brand_white);
  }

  .time-range-box {
    text-align: left;
    background-color: var(--greyscale_40) !important;
    margin-top: 12px;
    margin-left: -7px;
    width: 128px;
    height: 60px;
    padding: 8px 16px;
    border-radius: 12px;
    border: 1.5px solid;
    gap: 8px;

    .time-range-inner-section-value {
      .rmdp-container {
        .rmdp-wrapper.custom-timepicker {
          background-color: var(--brand_white);

          .rmdp-arrow-container {
            margin: 5px;
            &:hover {
              outline: 1px solid var(--brand_primary);
              background-color: var(--brand_white);
            }

            .rmdp-arrow {
              border-color: var(--brand_primary);
            }
          }
        }
      }
    }
  }

  .time-range-inner-section-label {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_72);
  }

  .time-range-inner-section-value {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_40);
    margin-left: -17px !important;
  }

  .event-calendar {
  }

  .event-date-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .event-date-inner-section {
  }

  .event-ai-inner-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .date-placeholder-style {
    color: var(--greyscale_64);
    font-size: toRem(12px);
    font-weight: 400;
    line-height: toRem(16px);
    letter-spacing: 0em;
    text-align: left;
  }

  .ai-placeholder-style-duclo {
    width: 113px !important;
    height: 16px;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_72);
  }

  .date-style {
    color: var(--brand_white);
    font-size: toRem(16px);
    font-weight: 500;
    line-height: toRem(20px);
    letter-spacing: 0em;
    text-align: left;
  }

  .ai-placeholder-container {
    height: 36px;

    .ai-placeholder-style-oncloud {
      color: var(--brand_white);
      font-size: toRem(12px);
      font-weight: 400;
      line-height: toRem(16px);
      height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }

    .date-style {
      color: var(--brand_white);
      font-size: toRem(16px);
      font-weight: 500;
      line-height: toRem(0px);
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .custom-timepicker.rmdp-wrapper,
  .rmdp-input {
    font-size: 16px !important;
    color: var(--brand_white);
    background-color: transparent;
  }

  .date-time-picker .rmdp-container {
    width: 65% !important;
  }

  .discover-placeholder-style {
    color: var(--primary_64);
    font-size: toRem(14px);
    font-weight: 600;
    line-height: toRem(20px);
  }

  .beta-tag-style {
    position: absolute;
    text-align: end;
    right: 0px;
    top: 4px;
  }

  .tags-margin {
    margin-top: 1rem !important;
  }

  .left-panel {
    padding-top: 16px;
    padding-left: 16px !important;
    text-align: left;
    height: calc(100vh - 52px);

    .filter-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 11px;
      gap: 10px;
      height: 28px;
      width: 248px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-left: 16px;

      .filter-label-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 16px;

        .filter-label-left,
        .filter-label-right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .filter-label-style {
      font-size: toRem(20px);
      font-weight: 700;
      line-height: toRem(28px);
      letter-spacing: 0em;
      text-align: left;
      color: var(--brand_white);
      margin-left: 4px;
    }
  }

  .left-panel-small {
    height: 28px;
    text-align: left;
    .filter-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 16px;
      gap: 10px;
      width: 252px;
      height: 28px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-left: 10px;
    }
    .filter-label-style {
      font-size: toRem(20px);
      font-weight: 700;
      line-height: toRem(28px);
      letter-spacing: 0em;
      text-align: left;
      color: var(--brand_white);
    }
  }

  .right-side-area {
    overflow: hidden;
  }

  .right-panel-container {
    padding-right: 24px;
  }

  .filter-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    width: 252px;
    height: 20px;
    background: var(--brand_white);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .filter-title-container-events {
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 16px;
    gap: 8px;
    height: 20px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .filter-error-container-events {
    display: flex;
    flex-direction: row;
    padding: 0px 16px;
    gap: 8px;
    width: 252px;
    height: 20px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: -11px;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .filter-title-container-without-height {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    width: 220px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .filter-title-container-event-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px 16px;
    gap: 8px;
    width: 252px;
    height: 20px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 10px;
    color: var(--brand_white);
  }

  .event-category-style {
    color: var(--brand_white);
    font-size: toRem(14px);
    font-weight: 600;
    line-height: toRem(20px);
    letter-spacing: 0em;
    text-align: left;
  }

  .event-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 252px;
    height: 204px;
  }

  .inside-event-type-layout {
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  .event-type {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 55px;
  }

  .custom-list {
    list-style-type: none;
    padding: 0 0 0 12px;

    &.event-type-list {
      padding-left: 0;
    }
  }

  .custom-list-value {
    font-size: toRem(14px);
    font-weight: 400;
    line-height: toRem(22px);
    letter-spacing: 0em;
    text-align: left;
    width: 217px;
    height: 25px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;
    color: var(--greyscale_88);

    &:hover {
      background-color: var(--primary_01);
    }
  }

  .custom-list-value-other {
    font-size: toRem(14px);
    font-weight: 400;
    line-height: toRem(21px);
    letter-spacing: 0em;
    text-align: left;
    height: 22px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;

    &:hover {
      background-color: var(--primary_01);
    }
  }

  .custom-list-value-other-show-more {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    width: 218px;
    height: 22px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;
  }

  .event-type-section-space {
    height: 70px;
  }

  .section-space-before-list-title {
    height: 0px;
  }

  .section-space-before-list-items {
    height: 57px;
  }

  .checkbox-style {
    margin-right: 10px;
    margin-top: 1px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    border-radius: 4px;
    border: 1.5px solid var(--greyscale_80);
    background: var(--brand_white);
  }

  .align-items-checkbox-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .checkbox-style-label {
    color: var(--greyscale_88);

    margin-top: -4px;
    span:hover {
      cursor: pointer;
    }
  }

  .show-more-label {
    margin-top: -4px;
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
    letter-spacing: 0em;
    text-align: left;
    color: var(--brand_primary);
    margin-left: 5px;
    cursor: pointer;
  }

  .show-more-icon-style {
    margin-top: -4px;
    cursor: pointer;
  }

  .toggle-device-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding-top: 2px;
  }

  .selected-event-type-style {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 600;
    color: var(--brand_primary);
  }

  .location-search-style {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 44px;
    color: var(--brand_white);
    background: var(--grayscale_24);
    border-radius: 12px;
    border: 0;
    margin: 12px 10px;
    width: 116%;

    &::placeholder {
      color: var(--greyscale_88);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(20px);
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 0;
      outline: 1px solid var(--brand_white);
    }
  }

  .license-plate-icon {
    margin-top: -89px;
    margin-left: 205px;
  }

  .pant-color-limit-exeed-message {
    font-size: toRem(12px);
    font-weight: 500;
    line-height: toRem(16px);
    letter-spacing: 0em;
    text-align: left;
    color: var(--brand_white);
    margin-left: -7px;
  }

  .section-tabs {
    display: inline-flex !important;
    border-radius: 12px !important;
    background-color: var(--hGreyscale_40) !important;
    justify-content: space-between !important;
    margin-top: 0px !important;
    width: 290px !important;
    height: 44px !important;

    font-family: 'Noto Sans' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

    &.device-tabs {
      width: 100% !important;
    }
  }

  .section-tabs-active {
    display: inline-flex !important;
    border-radius: 12px !important;
    background-color: var(--brand_white) !important;
    justify-content: space-between !important;
    margin-top: 0px !important;
    width: 290px !important;
    height: 44px !important;

    font-family: 'Noto Sans' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }

  .date-picker-events {
    .date-time-picker.dark {
      width: 100%;
      padding: 0;

      .custom-calendar.rmdp-wrapper {
        border-radius: 0;
        background-color: transparent !important;

        .rmdp-calendar {
          .rmdp-day-picker,
          .rmdp-month-picker,
          .rmdp-year-picker {
            background-color: var(--hGrayscale_24);

            .rmdp-week {
              margin-bottom: 3px;
              margin-top: 3px;
              color: var(--hGreyscale_64);
              justify-content: space-around;

              .rmdp-day:first-child {
                border-bottom-left-radius: 50%;
                border-top-left-radius: 50%;
              }

              .rmdp-day:last-child {
                border-bottom-right-radius: 50%;
                border-top-right-radius: 50%;
              }
              .rmdp-week-day {
                text-transform: none !important;
                color: var(--greyscale_72) !important;
              }
              .rmdp-day {
                color: var(--brand_white) !important;
                font-size: toRem(14px) !important;
                font-weight: 600 !important;
                line-height: toRem(20px) !important;
                letter-spacing: 0em !important;
                text-align: center !important;
                width: 42px !important;
                height: 42px !important;

                &.rmdp-day-hidden {
                  color: var(--greyscale_40) !important;
                }

                &:has(> .rmdp-disabled) {
                  display: flex;
                  width: 32px;
                  height: 32px;
                  padding: 5px 0px 7px 0px;
                  justify-content: center;
                  align-items: center;
                  border-radius: 999px;
                  border: 2px solid var(--primary_80);
                }

                &.rmdp-today span.sd {
                  background: var(--hGrayscale_24);
                  border-radius: 50%;
                  border: 3px solid var(--primary_40);
                }
              }

              .rmdp-disabled {
                color: var(--greyscale_40) !important;
                font-size: toRem(14px) !important;
                font-weight: 600 !important;
                line-height: toRem(20px) !important;
                letter-spacing: 0em !important;
                text-align: center !important;
                width: 42px !important;
                height: 42px !important;
              }
              .rmdp-day.rmdp-range.start span.sd {
                background-color: var(--brand_primary) !important;
                color: var(--brand_white);
                &::after {
                  content: ' ';
                  width: 10px;
                  background-color: var(--greyscale_40);
                }
              }
              .rmdp-day.rmdp-range.end span.sd {
                background-color: var(--brand_primary) !important;
                color: var(--brand_white);
              }
              .rmdp-range {
                background-color: var(--greyscale_40);
                box-shadow: none;
                .sd {
                  color: var(--brand_white);
                }
              }
            }

            .rmdp-ym {
              color: var(--hGreyscale_64);

              .rmdp-day:first-child {
                border-bottom-left-radius: 50%;
                border-top-left-radius: 50%;
              }

              .rmdp-day:last-child {
                border-bottom-right-radius: 50%;
                border-top-right-radius: 50%;
              }
              .rmdp-week-day {
                text-transform: none !important;
                color: var(--greyscale_72) !important;
              }
              .rmdp-day {
                color: var(--brand_white) !important;
                font-size: toRem(14px) !important;
                font-weight: 600 !important;
                line-height: toRem(20px) !important;
                letter-spacing: 0em !important;
                text-align: center !important;
                width: 32px !important;
                height: 32px !important;

                &.rmdp-day-hidden {
                  color: var(--greyscale_40) !important;
                }
              }
              .rmdp-disabled {
                color: var(--greyscale_40) !important;
                font-family: 'Noto Sans' !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                line-height: 20px !important;
                letter-spacing: 0em !important;
                text-align: center !important;
                width: 42px !important;
                height: 42px !important;
              }
              .rmdp-day.rmdp-range.start span.sd {
                background-color: var(--brand_primary) !important;
                color: var(--brand_white);
                &::after {
                  content: ' ';
                  width: 10px;
                  background-color: var(--greyscale_40);
                }
              }
              .rmdp-day.rmdp-range.end span.sd {
                background-color: var(--brand_primary) !important;
                color: var(--brand_white);
              }
              .rmdp-range {
                background-color: var(--greyscale_40);
                box-shadow: none;
                .sd {
                  color: var(--brand_white);
                }
              }
            }
          }
          .rmdp-header {
            margin-bottom: 6px !important;

            &.disabled {
              &:hover {
                background-color: var(--brand_primary) !important;
              }
            }

            .rmdp-header-values {
              > span:first-child,
              span:last-child {
                color: var(--brand_white) !important;
                background-color: transparent !important;
                padding: 0px !important;
              }
            }
          }
        }
      }
    }
  }

  .no-data-icon {
    width: 60px;
    height: 60px;
    padding: 4px;
    border-radius: 8px;
    margin-top: 14px;
    background-color: var(--hGrayscale_32);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .search-icon {
      color: var(--greyscale_72);
      height: 32px;
      width: 32px;
      margin-left: 4px;
    }
  }

  .clear-all-filter {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 32px;
    border: 1.5px solid var(--brand_primary);
    border-radius: 8px;
    margin-top: 25px;
    background-color: transparent;
    color: var(--primary_64);
    font-size: toRem(12px);
    font-weight: 600;
    line-height: toRem(16px); /* 133.333% */
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none !important;

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_32);
      background-color: var(--primary_32);
      color: var(--brand_white);
    }

    &:hover {
      background-color: var(--primary_40);
      color: var(--brand_white);
    }
  }

  .customContainer {
    border-bottom: 1px solid var(--grayscale_24);
    display: flex;
    flex-direction: row;
    width: 216px;
    height: 10px;
    margin-bottom: 17px;
    margin-left: 16px;
  }

  .custom-container-divider {
    display: flex;
    width: 1042px;
    margin-left: 24px;
    padding-bottom: 24px;
    align-items: flex-start;
    gap: 16px;
    border-bottom: 1px solid var(--hGrayscale_24);
  }

  .event-scroll-section {
    height: calc(100vh - 171px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
  }

  .search-box-icon-area {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .outer-border-left-panel {
    border-radius: 8px;
    width: 252px;
    height: calc(100vh - 129px) !important;
    margin-left: -4px;
    padding-top: 10px;
    position: relative;
  }

  .toggle-left-pabel-icon-small {
    margin-left: 176px;
    margin-top: -43px !important;
    z-index: 999 !important;
    cursor: pointer;
  }

  .toggle-left-pabel-icon-closed {
    z-index: 999 !important;
    cursor: pointer;
    margin-left: 28px;
    margin-top: 32px !important;
  }

  .device-wall-drawer-closed {
    margin: 0 24px 0 0;
    z-index: 9999 !important;
    .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 8px;
      margin: 72px 24px 24px 24px;
      height: calc(100vh - 52px) !important;
      gap: 8px;
      width: 79px;
      background-color: var(--greyscale_08);
    }
  }

  .toggle-left-panel-icon {
    z-index: 999 !important;
    cursor: pointer;
  }

  @media screen and (max-width: 3100px) {
    .outer-border-left-panel {
      width: 104%;
    }
  }

  @media screen and (max-width: 2760px) {
    .outer-border-left-panel {
      width: 105%;
    }
  }

  @media screen and (max-width: 2560px) {
    .outer-border-left-panel {
      width: 106%;
    }
  }

  @media screen and (max-width: 1654px) {
    .outer-border-left-panel {
      width: 109%;
    }
  }

  @media screen and (max-width: 1500px) {
    .sticky-header-scroll {
      font-size: toRem(16px);
      font-weight: 500;
      line-height: toRem(20px);
      letter-spacing: 0em;
      text-align: left;
      color: var(--greyscale_40);
      display: flex;
      align-items: end;
      margin-bottom: 0px !important;
    }
    .scroll-container {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
    .search-filter-category {
      float: inline-end;
    }
    .search-filter-category .right-filter-box .time-box search-ai-duclo {
      width: 205px;
    }
    .beta-tag-style {
    }
    .event-date-inner-section {
    }
    .discover-placeholder-style {
      font-size: 11px;
    }
    .customContainer {
      width: 200px;
    }
    .all-events-container {
      float: inline-end;
    }
    .custom-container-devider {
      border-bottom: 1px solid var(--grayscale_96);
      display: flex;
      flex-direction: row;
      width: 92%;
      margin-bottom: 4px;
      margin-top: 0px;
      margin-left: 85px;
      height: 9px;
    }
    .scroll-container {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
    .custom-list-value-other {
      color: var(--greyscale_88);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(21px);
      letter-spacing: 0em;
      text-align: left;
      height: 28px;
      border-radius: 4px;
      gap: 10px;
      cursor: pointer;
      padding: 4px 12px;
    }

    .custom-list-value-other-show-more {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 22px;
      border-radius: 4px;
      gap: 10px;
      width: 217px;
      cursor: pointer;
    }
    .custom-list-value {
      width: 217px;
    }
    .outer-border-left-panel {
      min-width: 100%;
      padding-right: 16px;
    }
    .left-panel.filter-label {
      width: 165px !important;
    }
  }

  @media screen and (max-width: 1400px) {
    .event-scroll-section {
      width: 235px;
    }
  }

  @media screen and (max-width: 992px) {
    .event-scroll-section {
      width: 220px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .outer-border-left-panel {
      width: 150%;
    }
    .all-events-container {
      width: 100%;
      margin-left: 22px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1240px) {
    .all-events-container {
      width: 100%;
      margin-left: 22px;
    }
  }

  @media screen and (min-width: 993px) and (max-width: 1199px) {
    .toggle-device-list {
      display: block !important;
      margin-left: 0px !important;
      margin-left: 20px !important;
    }
    .all-events-container {
      width: 100%;
      margin-left: 22px;
    }
  }

  @media screen and (min-width: 817px) and (max-width: 1200px) {
    .device-wall-drawer-closed
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      width: 61px !important;
      margin: 52px 6px 24px 0px !important;
    }
    .device-wall-drawer-events
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      margin: 52px 6px 24px 0px !important;
    }
    .toggle-left-pabel-icon-closed {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: 611px) and (max-width: 816px) {
    .toggle-left-pabel-icon-closed {
      margin-left: 5px;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 816px) {
    .device-wall-drawer-closed
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      width: 36px !important;
      margin: 120px 6px 24px 6px !important;
    }
    .device-wall-drawer-events
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      margin: 120px 6px 24px 6px !important;
    }
  }

  @media screen and (max-width: 875px) {
    .search-filter-category .right-filter-box {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 766px) {
    .device-wall-drawer-closed
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      width: 36px !important;
      margin: 154px 6px 24px 6px !important;
    }
    .device-wall-drawer-events
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      margin: 154px 6px 24px 6px !important;
    }
  }

  @media screen and (max-width: 610px) {
    .device-wall-drawer-closed
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      width: 36px !important;
      margin: 174px 6px 24px 6px !important;
    }
    .toggle-left-pabel-icon-closed {
      margin-left: 5px;
    }
    .device-wall-drawer-events
      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      margin: 174px 6px 24px 6px !important;
    }
  }

  @media screen and (min-width: 150px) and (max-width: 991px) {
    .toggle-device-list {
      display: block !important;
      margin-top: -37px !important;
      margin-left: 168px !important;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 992px) {
    .toggle-device-list {
      display: block !important;
      margin-left: 0px !important;
      margin-left: 20px !important;
    }
  }

  @media screen and (max-width: 1240px) {
    .right-panel-container {
      padding-left: 22px;
    }
    .search-filter-category {
      float: inline-end;
    }
    .search-filter-category .right-filter-box .time-box search-ai-duclo {
      width: 205px;
    }
    .customContainer {
      width: 158px;
    }
    .custom-list-value-other {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 22px;
      border-radius: 4px;
      gap: 10px;
      cursor: pointer;
    }
    .custom-list-value-other-show-more {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 22px;
      border-radius: 4px;
      gap: 10px;
      width: 185px;
      cursor: pointer;
    }
    .custom-list-value {
      width: 185px;
    }
    // right side area in events page starts
    .right-filter-box .time-box .search-event-date {
      width: 179px !important;
    }
    .right-filter-box .time-box .search-event-date-active {
      width: 179px !important;
    }
    .beta-tag-style {
    }
    .date-style {
      font-family: 'Noto Sans';
      font-size: 10px;
    }
    .event-date-inner-section {
    }
    .discover-placeholder-style {
      font-size: 11px;
    }
    .all-events-container {
      margin-left: 22px;
      float: inline-end;
    }
    // right side area in events page ends
  }

  @media screen and (max-width: 1024px) {
    /* Your styles for small desktop devices go here */
    .search-filter-category {
      float: inline-end;
    }
    .customContainer {
      width: 158px;
    }
    .all-events-container {
      width: 92%;
      float: inline-end;
    }
    .custom-list-value-other {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 22px;
      border-radius: 4px;
      gap: 10px;
      cursor: pointer;
    }
    .custom-list-value-other-show-more {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 22px;
      border-radius: 4px;
      gap: 10px;
      width: 156px;
      cursor: pointer;
    }
    .custom-list-value {
      width: 156px;
    }
    // right section style starts
    .right-filter-box .time-box .search-event-date {
      width: 179px !important;
    }
    .right-filter-box .time-box .search-event-date-active {
      width: 179px !important;
    }
    .search-filter-category .right-filter-box .time-box search-ai-duclo {
      width: 205px;
    }
    .beta-tag-style {
    }
    .date-style {
      font-family: 'Noto Sans';
      font-size: 10px;
    }
    .event-date-inner-section {
    }
    .discover-placeholder-style {
      font-size: 11px;
    }
  }

  .icon-separator {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: var(--greyscale_40);
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 4px;
  }

  .search-box-style {
    display: flex;
    align-items: center;
  }

  .sticky-header-scroll {
    font-size: toRem(16px);
    font-weight: 500;
    line-height: toRem(20px);
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_40);
    display: flex;
    align-items: end;
    margin-bottom: 7px;
    margin-left: 12px;
  }

  .sticky-header-scroll-label {
    margin-left: 4px;
    color: var(--brand_white);
  }

  .show-more-footer {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .show-more-text {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary_40);
  }

  .show-more-footer-inner-area {
    display: flex;
    cursor: pointer;
  }

  .sticky-header-area {
    position: sticky;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px !important;
    background-color: var(--grayscale_32);
    backdrop-filter: blur(2px) !important;
  }

  .empty-section-style {
    height: 22px;
  }

  .full-width-section-style {
    width: 100%;
  }

  .event-image-search {
    padding: 0;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary_32);
    margin-right: -6px;
  }

  .loading-container {
    position: fixed;
    top: 50%;
    left: 55%;
  }

  .device-wall-drawer-events {
    margin: 0 24px 0 0;

    .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 8px;
      margin: 100px 24px 24px 24px;
      height: calc(100vh - 52px) !important;
      gap: 8px;
      background-color: var(--greyscale_08);
      padding-top: 42px;
      width: 212px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--primary_04);
      min-height: 44px;

      &-title {
        color: var(--brand_white);
        font-size: toRem(20px);
        font-weight: 600;
        line-height: toRem(28px);

        &.hidden:has(
            .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium
          ) {
          padding-left: 4px;
        }
        &.disabled {
          color: var(--primary_05);
        }
      }

      &-toggle-icon {
        padding-left: 0;
      }

      .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        padding-right: 0;
      }
    }

    &-toggle-icon {
      color: var(--brand_white);

      &.toggle-close {
        transform: rotate(90deg);
      }

      &.toggle-open {
        transform: rotate(270deg);
      }
    }

    &-list {
      padding: 0;

      &.view-list {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &::-webkit-scrollbar {
          width: 5px;
          margin-right: -4px;
        }
      }

      li {
        display: flex;
        justify-content: space-around;
        margin-top: 8px;
        &:first-child {
          margin-top: 0px;
        }

        .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-root {
          padding: 8px 8px 8px 16px;

          .MuiListItemIcon-root {
            margin-right: 0;
          }

          .MuiTypography-root {
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Noto Sans';
          }
        }
      }

      &-item {
        &-button {
          padding-left: 11px !important;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;

          &:focus,
          &:focus-within,
          &:active {
            background: var(--primary_03);
          }
          &.active {
            background: var(--primary_03);
          }

          &:hover {
            background: var(--primary_01);
          }

          &.drag-over {
            background: var(--primary_08);
          }

          &.disabled {
            color: var(--primary_05);
            pointer-events: none;
          }
        }

        &-not-active-button {
          &:focus,
          &:focus-within,
          &:active {
            background: transparent;
          }
          &.active {
            background: transparent;
          }
        }

        &-btn {
          border-radius: 8px;
          background: var(--primary_08);
          margin-top: 4px;
          margin-bottom: 4px;
          padding: 8px 16px !important;
          height: 38px;

          .MuiListItemIcon-root {
          }
        }

        &.active-list-item {
          border-left: 4px solid var(--primary_64);
          margin-left: -8px;
          padding-left: 4px;
        }

        &-notifications {
          display: flex;
          height: 16px;
          min-width: 16px;
          padding: 0px 2px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          background: var(--primary_64);

          color: var(--brand_white);
          text-align: center;
          font-size: toRem(12px);
          font-weight: 400;
          line-height: toRem(16px);
        }
      }
    }

    &-divider {
      border: 1px solid var(--primary_01);
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &-add-view-form {
      & .form-control:focus,
      .form-control:active,
      .form-control:hover {
        box-shadow: unset;
        border-color: var(--primary_02);
      }
      .form-control.is-invalid:focus,
      .form-control.is-invalid:active,
      .form-control.is-invalid:hover {
        border-color: var(--bs-form-invalid-border-color);
      }

      & input.form-control {
        height: 36px;
        border-radius: 0.5rem;
        background-color: var(--primary_04);
        padding: 12px 60px 14px 16px;
        color: var(--brand_white);
        text-indent: 2px;
      }

      & .placeholder-wrapper {
        height: 36px;
        padding: 8px 8px 8px 16px;
        left: 1rem;
      }

      & .icon-after-input {
        height: 36px;

        & .icon-container {
          display: flex;
          align-items: center;
        }

        & .cross-icon {
          cursor: pointer;

          & path {
            stroke: var(--brand_white);
          }
        }

        & .checked-icon {
          margin-left: 0.25rem;
          cursor: pointer;

          & rect {
            fill: var(--primary_40);
          }
          & path {
            stroke: var(--brand_white);
          }
        }
      }
    }

    &-new-view {
      &-btn {
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: var(--primary_40);
        height: 38px;
        padding: 8px;
        width: 100%;

        &.hidden {
          display: none !important;
        }

        &:hover {
          background: var(--primary_64);
        }

        &:focus,
        &:active {
          outline: 1px solid var(--brand_white);
          background: var(--primary_40);
        }

        &.drag-over {
          outline: 1px dashed var(--primary_64);
          background: var(--primary_08);
        }

        &.disabled {
          background: var(--primary_01);
        }

        &-label {
          overflow: hidden;
          color: var(--brand_white);
          text-overflow: ellipsis;
          font-size: toRem(14px);
          font-weight: 400;
          line-height: toRem(20px);
          text-transform: initial;
          font-family: 'Noto Sans';

          &-plus {
            color: var(--brand_white);
            font-size: 1.25rem;
            line-height: toRem(22px);
            margin-top: -2px;
            margin-right: 12px;
            &.disabled {
              color: var(--primary_05);
            }
          }
          &.disabled {
            color: var(--primary_05);
          }
        }
      }

      &-field {
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
          &:hover {
            outline: 0;
            border-radius: 8px;
            border-color: var(--greyscale_64);
            border-width: 0;
          }

          input {
            display: flex;
            padding: 8px 8px 8px 16px;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--greyscale_64);
            color: var(--brand_white);
            background: var(--primary_04);
            outline: 0;

            &:active,
            &:hover {
              border: 1px solid var(--greyscale_64);
              outline: 0;
            }
          }
        }
      }

      &-clear-icon {
        color: var(--primary_01);
      }

      &-check-icon {
        color: var(--primary_40);
      }
    }

    &-section {
      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;
        padding: 8px 12px;

        &.active-list-item {
          border-left: 4px solid var(--primary_64);
          margin-left: -8px;
          padding-left: 16px;
        }
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 8px;
        margin-right: 8px;

        &.hidden {
          display: none;
        }
        &.disabled {
          color: var(--primary_05);
        }
      }
    }

    &-help {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;
        width: 100%;

        &.icon-only {
          height: 100%;
        }
      }

      &-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
      }

      &-icon {
        margin-right: 8px;
      }

      &-text {
        color: var(--greyscale_80);
        font-size: toRem(16px);
        font-weight: 400;
        line-height: toRem(20px);
      }
    }
  }

  .right-panel-margin {
    margin-left: 181px !important;
  }

  .right-panel-margin-aligned {
    margin-left: 0px !important;
  }

  .right-panel-margin-big-screen {
    margin-left: 113px !important;
  }

  .right-panel-margin-big-screen-open {
    margin-left: 455px !important;
  }

  .right-panel-margin-medium-screen-open {
    margin-left: 253px !important;
  }

  .right-panel-margin-medium-screen {
    margin-left: 86px !important;
    width: calc(100% - 86px);
  }

  .right-panel-margin-small-screen {
    margin-left: -24px !important;
  }

  .right-panel-margin-small-shift-screen {
    margin-left: -14px !important;
  }

  .right-panel-margin-small-shift-top-screen {
    margin-left: -24px !important;
    margin-top: 31px !important;
  }

  .right-panel-margin-small-top-section-screen {
    margin-top: 33px !important;
  }

  .right-panel-margin-extra-small-section-screen {
    margin-left: 11px !important;
  }

  .small-margin {
    display: flex;
    align-items: flex-start;
    margin-left: 9px;
  }
}
