
.account-profile-icon {
  position: relative;

  .edit-pencil-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    left: 56px;
    top: 56px;
    background: var(--primary_40);
    border-radius: 10px;

    label {
      height: 100%;
      width: 100%;
      text-align: center;
      cursor: auto;
    }

    input {
      display: none;
    }

    svg {
      stroke: var(--brand_white);
    }
  }
  .disabled-icon {
    background: var(--greyscale_88);
  }
}

.organization-settings {
  .form-submit-main {
    text-align: end;
    margin-top: 66px;
  }

  .range-field-container {
    text-align: left;
  }
}
.update-password{
  .row-text-left{
    text-align: left !important;
  }
  .row-title{
    color: var(--brand_black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .password-hints{
  color: var(--greyscale_08);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  }
  .col.input-with-icons {
    .icon-before-input {
      height: 52px;

      svg {
        fill: unset;
        stroke: var(--brand_primary);

        path {
          fill: var(--brand_white);
        }
      }
    }

    .icon-after-input {
      height: 52px;

      svg {
        fill: unset;
        stroke: var(--greyscale_56);

        path {
          fill: var(--greyscale_56);
        }
      }
    }

    .form-control {
      height: 52px;
    }

    .placeholder-wrapper {
      height: 52px;
      padding: 16px;
    }
  }

  .left-to-icon{
    margin-left: 8px;
  }
  .m-t-6{
    margin-top: 6px;
  }
  .m-t-50{
    margin-top: 50px;
  }
  .m-t-20{
    margin-top: 20px !important;
  }
  .m-t-12{
    margin-top: 12px !important;
  }

  .set-to-right{
    justify-content: right;
    display: flex;
  }
  .d-flex{
    display: flex;
  }
  .m-t-8{
    margin-top: 8px;
  }
  .m-t-40{
    margin-top: 40px;
  }
}
.container-session {
  margin-top: 15px;
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 12px;
  background: var(--grayscale_96);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .header-title {
    color: var(--greyscale_08);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .session-event-conatiner {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  
    &.disabled {
      pointer-events: none;
    }
  
    .left-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
     .event-icon {
      width: 32px;
      height: 32px;
      margin-left: -5px;
      color: var(--greyscale_40);
  
      @media screen and (max-width: 1000px) {
        width: 40px;
        height: 40px;
  
      }
    }
  
      .title-discription-conatiner {
        margin-left: 5px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
  
        .event-title {
          text-align: left;
          margin-top: -5px;
          color: var(--greyscale_40);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          @media screen and (max-width: 1000px) {
            margin-top: 0px;
      
          }
        }
  
      }
    }
  
    .input-check-events {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }
    .settings-block-account {
      margin-top: -19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--grayscale_96);
      border-radius: 12px;
    
      .add-edit-icon {
        margin-right: 10px;
        cursor: pointer;
        color: var(--greyscale_08);
        &.off-status {
          pointer-events: none;
          cursor: not-allowed;
          color: var(--greyscale_40);
        }
      }
    }
    
  }
}

