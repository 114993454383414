@import '../../../../assets/css/_utilities.scss';

.sub-header-analytics {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  margin-top: 18px;
  margin-bottom: 18px;
}

.range-field-label {
  color: var(--brand_black);
  font-weight: 900;
  font-size: toRem(16px);
  line-height: 16px;
}

.sitemodal-inner {
  margin-top: 10px;
}

.settings-block-analytics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  background: var(--grayscale_96);
  border-radius: 12px;

  .add-edit-icon {
    margin-right: 10px;
    cursor: pointer;
    color: var(--greyscale_08);
    &.off-status {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--greyscale_40);
    }
  }
}

.advanced-activity-zone {
  display: flex;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;

  .activity-field-label {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
  }

  .add-icon {
    cursor: pointer;
  }
}

.analytics-settings-activity-zone {
  .modal-header {
    padding-top: 0px;
  }
  .activity-zone-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_56);
    margin-top: 4px;
  }

  .hr-devider {
    width: 120px;
    height: 4px;
    border-radius: 16px;
    background: var(--brand_primary);
    margin: 24px 0;
  }

  .modal-sub-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .add-zone-btn {
    margin: 0px 8px;
  }

  .snap-shot-container {
    position: relative;

    .cam-1x {
      width: 478px;
    }

    .cam-snapshot {
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      aspect-ratio: 16/9;
      object-fit: fill;
      border-radius: 12px;
      width: 478px;

      .cam-snapshot-wrapper {
        height: 100%;
      }
    }

    & .live-snapshot {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      background: var(--greyscale_08);

      & .live-snapshot-image {
        z-index: 10;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        aspect-ratio: 16 / 9;
      }
    }
  }
}

.regions-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  & .region-btn {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    & .circle-dot {
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background-color: var(--brand_black);
    }

    & svg.cross-icon {
      path {
        stroke-width: 1.5px;
        stroke: var(--primary_40);
      }
    }

    &.selected-region {
      background-color: var(--primary_40) !important;
      color: var(--brand_white) !important;

      & svg.cross-icon {
        path {
          stroke-width: 1.5px;
          stroke: var(--brand_white);
        }
      }
    }
  }
}

.add-zone-container {
  .input-box {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background-color: transparent;
    width: 100%;
    position: relative;
    z-index: 10;

    &:disabled {
      background-color: var(--grayscale_96);
    }

    &:focus + .custom-placeholder {
      display: none;
    }

    &.summary-input {
      &::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &::-moz-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &:-ms-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
    }
  }

  & .zone-input-wrapper {
    & .info-msg {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--greyscale_40);
      padding: 4px 20px 0 20px;

      &.error {
        color: var(--error_64);
      }
    }

    &.duration {
      & .info-msg {
        padding: 4px 16px 0 16px;
      }
    }

    & .medium-no-value {
      border-radius: 12px;
      border: 1.5px solid var(--greyscale_88);
      z-index: 10;
      background-color: var(--grayscale_96);
    }
  }

  & .checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--grayscale_96);
    border-radius: 12px;
    margin-bottom: 8px;
    &:hover {
      background-color: var(--greyscale_88);
    }
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      text-align: left;
      color: var(--greyscale_08);
    }

    &-input-checkbox {
      height: 20px;
      width: 20px;
      min-width: 20px;

      &:disabled {
        &:checked::after {
          background-color: var(--greyscale_48);
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: var(--greyscale_48);
        border-radius: 3px;
        outline: none;
        border: 1.5px solid var(--greyscale_40);
      }
    }

    & .info-message-container {
      display: flex;

      svg {
        height: 16px;
        width: 16px;
        min-width: 16px;
      }

      & .info-message {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 0em;
        margin-left: 4px;
        color: var(--greyscale_56);
      }
    }
  }
}

.error-conatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
  background: var(--error_96);
  border-radius: 12px;
  color: var(--error_24);

  .icon-warning-col {
    color: var(--error_24);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .error-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
  }
}

.warning-conatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
  background: var(--warning_96);
  border-radius: 12px;
  color: var(--warning_24);

  .icon-warning-col {
    color: var(--warning_24);
    height: 20px;
    width: 20px;
    cursor: pointer;

    path {
      color: var(--warning_24);
    }
  }

  .warning-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--warning_24);

    span {
      font-weight: 700;
    }
  }
}

.input-group-container {
  display: flex;
  gap: 1rem;

  .zone-input-wrapper {
    &.high,
    &.medium {
      width: 56%;
    }
    &.duration {
      width: 28%;
    }
    &.seconds {
      display: flex;
      align-items: center;
      height: 50px;
      width: 14%;
    }
  }
}

.line-container {
  position: absolute;
  top: 0;
  z-index: 12;
}

.region-container {
  div[data-dir] {
    border-radius: 4px;
    outline: none !important;
    border: none !important;
  }
  &.region0 {
    div[data-dir] {
      background-color: var(--custom_color_18);
    }
  }
  &.region1 {
    div[data-dir] {
      background-color: var(--warning_64);
    }
  }
  &.region2 {
    div[data-dir] {
      background-color: var(--success_48);
    }
  }
  &.region3 {
    div[data-dir] {
      background-color: var(--custom_color_11);
    }
  }
  &.region4 {
    div[data-dir] {
      background-color: var(--error_48);
    }
  }
  &.region5 {
    div[data-dir] {
      background-color: var(--error_80);
    }
  }
  &.region6 {
    div[data-dir] {
      background-color: var(--primary_03);
    }
  }
  &.region7 {
    div[data-dir] {
      background-color: var(--avatar_dark_lime);
    }
  }
  &.region8 {
    div[data-dir] {
      background-color: var(--custom_color_1);
    }
  }
  &.region9 {
    div[data-dir] {
      background-color: var(--error_24);
    }
  }
}

.component-container {
  position: relative;
  & .disable-bg {
    background-color: white;
    opacity: 0.5;
    position: absolute;
    z-index: 14;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 641px) {
  .analytics-settings-activity-zone .modal-dialog .modal-content {
      min-width: 582px;
  }
}