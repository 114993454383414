@import '../../../../assets/css/_utilities.scss';


.device-settings-block {
  &.editable-off-offline {
    pointer-events: none;
  }

  .settings-block {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .select-field-wrap {
      margin-top: 0;
      margin-bottom: 0;

      .form-select {
        margin-bottom: 0;
      }
    }
  }
}

.alert.alert-danger {
  background-color: var(--error_96);
  border-color: transparent;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;

  .alert-heading {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(16px);
    line-height: 20px;
    color: var(--error_24);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
    margin-bottom: 0;
  }

  .btn-close {
    height: 10px;
    width: 10px;
  }
}

.device-settings-restart-required {
  .modal-header {
    padding-bottom: 4px;
  }
}

.recording-video-codec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 16px;

  .label-recording {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--brand_black);
  }

  .codec-selection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.selection-disable {
      pointer-events: none;
      //background-color: var(--greyscale_64);
    }

    .label-codec {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }
  }
    .codec-selection>input {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  
    .codec-selection input[type='radio'] {
      -webkit-appearance: none;
      border-radius: 50%;
      outline: none;
      border: 2px solid var(--greyscale_80);
      background: var(--brand_white);
    }
  
    .codec-selection input[type='radio']:before {
      content: '';
      display: block;
      width: 64%;
      height: 64%;
      margin: 18% auto;
      border-radius: 50%;
    }
  
    .codec-selection input[type="radio"]:checked:before {
      background: var(--primary_40);
    }
  
    .codec-selection input[type="radio"]:checked {
      border-color: var(--primary_40);
    }
  
}
