.channel-details.device-tabs {
  .section-tabs {
    .section-tab {
      width: 252px !important;
      &.device-tabs {
        width: 100% !important;
      }
    }
    .section-tab-label.d-none {
      display: initial !important;
    }
  }
}

.rmdp-header {
  .rmdp-header-values {
    flex: auto;
  }
}

.channel-details.device-tabs {
  margin-bottom: 0px !important;
}

.margin-event-calender {
  margin-top: -18px;
  margin-left: 10px;
}

.date-time-picker {
  .custom-calendar.rmdp-wrapper {
    .rmdp-calendar .rmdp-header {
      margin-bottom: 6px !important;

      &.disabled {
        &:hover {
          background-color: var(--brand_primary) !important;
        }
      }

      .rmdp-header-values {
        > span:first-child,
        span:last-child {
          color: var(--brand_white) !important;
          background-color: transparent !important;
          padding: 0px !important;
        }
      }
    }
  }
}
