@import '../../assets/css/base';

.modal-dialog.modal-fullscreen {
  .modal-content {
    border-radius: 0px;
  }
}

.search-label-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .explore-text {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--greyscale_08);
  }

  .supported-lang {
    font-style: italic;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--greyscale_08);
    opacity: 0.6;
  }
}

.try-saying-something {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);
  opacity: 0.6;
}

.search-container-history {
  margin-top: 20px;

  .search-list-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;

    .search-history-text {
      width: 90%;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(20px);
      line-height: 28px;
      color: var(--greyscale_40);
    }
  }
}

.voice-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .outer-view {
    width: 95px;
    height: 95px;
    position: fixed;
    bottom: 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: transparent;

    &.voice-listening {
      background: var(--primary_96);
      animation: mymove 1s infinite;
    }

    @keyframes mymove {
      from {
        background-color: var(--primary_88);
      }

      to {
        background-color: var(--primary_96);
      }
    }

    .voice-recognition-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px;
      gap: 8px;
      width: 64px;
      height: 64px;
      background: var(--primary_40);
      border-radius: 64px;
      position: fixed;
      bottom: 25px;
    }
  }
}
