@import '../../assets/css/base.scss';

.metric-container {
  border: 0 !important;
  text-align: left;

  .card-body {
    padding: 10px 0;
  }

  .title-wrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title-data {
      color: var(--greyscale_08);
      font-size: toRem(20px);
      font-weight: 700;
    }

    .title-description {
      color: var(--greyscale_56);
      font-size: toRem(12px);
      font-weight: 400;
    }
  }

  .body-wrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;

    .metric-source {
      .source-text {
        color: var(--greyscale_08);
        font-size: toRem(10px);
        font-weight: 600;
      }

      .source-subtext {
        color: var(--greyscale_40);
        font-size: toRem(10px);
        font-weight: 400;
      }
    }

    .metric-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: toRem(10px);
      font-weight: 600;
      padding: 6px 16px;
      min-width: 64px;
      text-align: center;
      gap: 6px;

      &.pink {
        color: var(--error_24);
        background-color: var(--error_96);
      }

      &.light-blue {
        color: var(--diff_text_positive);
        background-color: var(--diff_positive);
      }

      &.cameraAvailability {
        color: var(--diff_text_positive);
        background-color: var(--error_96);
      }
    }
  }
}
