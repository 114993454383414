@import '../../assets/css/utilities';

.header-main-layout-pos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;

  .header-title-pos {
    text-align: start;
    color: var(--brand_black);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
  }

  .right-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .btn-data-mapping {
      margin-right: 8px;
    }

    .upload-pos-file {
      background-color: var(--brand_primary);
      color: var(--brand_white);
      border-color: var(--brand_primary);
      border-width: 2px;
      border-radius: 12px;
      min-height: 44px;
      height: 44px;
      width: 212px;
      border-style: solid;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;

      &.disable-state-upload {
        pointer-events: none;
        background-color: var(--greyscale_88);
        border-color: var(--greyscale_88);
        color: var(--greyscale_64);
      }

      &:hover {
        background-color: var(--primary_24);
        border-color: var(--primary_24);

      }

      .upload-pos-btn {
        display: inline-block;
        top: 30%;
        left: 40%;
        position: absolute;
        width: 85px;
        height: 85px;
        padding: 5px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: var(--brand_white);
        --_m: conic-gradient(#0000 2%, #000), linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: s3 1s infinite linear;
        vertical-align: middle;
      }

      @keyframes s3 {
        to {
          transform: rotate(1turn);
        }
      }

    }
  }
}

.file-failed-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 12px;
  background: var(--error_96);
  width: 100%;
  height: 44px;
  align-items: center;

  .left-file-layout {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .title-file-fail {
      margin-left: 10px;
      color: var(--error_24);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .icon-cancel-pos {
    margin-right: 10px;
  }
}
.layout-no-setup {
  margin-top: 28px;
  display: flex;
  width: 100%;
  height: 385px;
  padding: 44px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--grayscale_96);

  .message-content-pos {
    width: 490px;
    color: var(--greyscale_56);
    text-align: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "Noto Sans";
    margin-top: 10px;
  }
  .btn-margin{
    margin-top: 15px;
  }
}

.table-cell-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  color: var(--brand_black);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.table-cell-status-pos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .status-icon-layout {
    color: var(--error_48);

    &.complete{
      color: var(--success_48);
    }
    &.stalled{
      color: var(--warning_48);
    }
  }

  .cell-title-pos-label{
     color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
  }

  .status-message-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;

    .title-status {
      color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .title-status-message {
      color: var(--greyscale_40);
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 500px;
    }
  }
}