@import '../../assets/css/base';

.tags-container {
  overflow-x: hidden;
}

.scroll-container-tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  width: 100%;
  height: 40px;
  overflow-y: hidden;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    height: 5px;
    width: 1px;
  }

  .child-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    background: var(--primary_96);
    border-radius: 10px;
    padding: 0.375rem 0.625rem;

    &.all-tag-bg {
      background: transparent;
    }

    &.tag-vlm {
      background: var(--tag_vlm_bg);
    }

    .icon-people {
      &.tag-vlm {
        color: var(--tagvlm);
      }
    }

    .text-tag {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(12px);
      line-height: 16px;
      display: flex;
      align-items: center;
      color: var(--primary_32);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.all-tags-label {
        margin-left: 0px;
        color: var(--primary_40);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      &.tag-vlm {
        color: var(--tagvlm);
      }
    }

    .cross-icon {
      color: var(--primary_32);

      &.tag-vlm {
        color: var(--tagvlm);
      }
    }
  }
}

.personal-parent-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--grayscale_96);
  border-radius: 12px;
  margin-bottom: 16px;
  padding-left: 20px;

  .person-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .icon-person {
        height: 20px;
        width: 20px;
        align-items: center;
      }

      .search-person-container {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .search-by-person {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
          display: flex;
          align-items: center;
          color: var(--brand_black);
        }
      }
    }
  }

  .expanded-container {
    height: auto;
    padding-bottom: 20px;
    width: 100%;

    .select-more-text {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
      color: var(--greyscale_8);
      padding: 0px 20px 12px 0px;
    }
    .parent-faces-container {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 16px;
      gap: 4px;
      background: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;

      .face-toggle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        .left-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;

          .face-icon {
            color: var(--text_color);
          }

          .container-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .show-faces-label {
              margin-left: 5px;
              font-style: normal;
              font-weight: 500;
              font-size: toRem(14px);
              line-height: 20px;
              display: flex;
              align-items: center;
              color: var(--text_color);
            }

            .warning-message {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: baseline;

              .icon-info {
                color: var(--greyscale_40);
              }

              .info-message {
                margin-left: 2px;
                font-style: normal;
                font-weight: 500;
                font-size: toRem(10px);
                line-height: 16px;
                color: var(--greyscale_40);
              }
            }
          }
        }

        .right-container {
          background: var(--brand_white);
          align-items: center;
          display: flex;
          justify-content: center;

          .toggle-field-wrap {
            background: var(--brand_white);
            margin-top: -10px;
          }
        }
      }

      .text-label-accessories {
        font-style: normal;
        font-weight: 600;
        font-size: toRem(14px);
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--text_color);
      }

      .age-filter-box {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        color: var(--greyscale_40);

        .left-container-age {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;

          .young-layout {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            height: 40px;
            background: var(--brand_white);
            border: 1.5px solid var(--greyscale_88);
            border-radius: 12px;
            cursor: pointer;

            &.selected {
              border: 1.5px solid var(--primary_40);
              color: var(--primary_40);
              background: var(--primary_96);
            }
          }

          .middle-age-layout {
            margin-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            height: 40px;
            background: var(--brand_white);
            border: 1.5px solid var(--greyscale_88);
            border-radius: 12px;
            cursor: pointer;

            &.selected {
              border: 1.5px solid var(--primary_40);
              color: var(--primary_40);
              background: var(--primary_96);
            }
          }
        }
      }

      .accessories-container {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        gap: 8px;

        .glass-layout {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 4px;
          height: 40px;
          background: var(--brand_white);
          border: 1.5px solid var(--greyscale_88);
          border-radius: 12px;
          cursor: pointer;

          .icon-mf {
            height: 20px;
            width: 20px;
            color: var(--greyscale_40);

            &.selected {
              color: var(--primary_48);
            }
          }

          &.selected {
            border: 1.5px solid var(--primary_40);
            color: var(--primary_40);
            background: var(--primary_96);
          }
        }

        .mask-layout {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 4px;
          height: 40px;
          background: var(--brand_white);
          border: 1.5px solid var(--greyscale_88);
          border-radius: 12px;
          cursor: pointer;

          &.selected {
            border: 1.5px solid var(--primary_40);
            color: var(--primary_40);
            background: var(--primary_96);
          }

          .icon-mf {
            height: 20px;
            width: 20px;
            color: var(--greyscale_40);

            &.selected {
              color: var(--primary_48);
            }
          }
        }
      }
    }

    .age-label {
      margin-top: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
    }

    .age-filter-box {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      gap: 8px;
      color: var(--greyscale_40);

      .left-container-age {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .young-layout {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          height: 40px;
          background: var(--brand_white);
          border: 1.5px solid var(--greyscale_88);
          border-radius: 12px;
          cursor: pointer;

          &.selected {
            border: 1.5px solid var(--primary_40);
            color: var(--primary_40);
            background: var(--primary_96);
          }
        }

        .middle-age-layout {
          margin-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          height: 40px;
          background: var(--brand_white);
          border: 1.5px solid var(--greyscale_88);
          border-radius: 12px;
          cursor: pointer;

          &.selected {
            border: 1.5px solid var(--primary_40);
            color: var(--primary_40);
            background: var(--primary_96);
          }
        }
      }
    }

    .gender-container {
      margin-top: 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      color: var(--greyscale_40);
      gap: 8px;

      .male-layout {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        height: 40px;
        background: var(--brand_white);
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
        cursor: pointer;

        .icon-mf {
          height: 20px;
          width: 20px;
          color: var(--greyscale_40);

          &.selected {
            color: var(--primary_48);
          }
        }

        &.selected {
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          background: var(--primary_96);
        }
      }

      .female-layout {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        height: 40px;
        background: var(--brand_white);
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
        cursor: pointer;

        &.selected {
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          background: var(--primary_96);
        }

        .icon-mf {
          height: 20px;
          width: 20px;
          color: var(--greyscale_40);

          &.selected {
            color: var(--primary_48);
          }
        }
      }
    }

    .pant-color {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
      margin-bottom: 4px;
    }

    .lable-show {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(12px);
      line-height: 16px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
      margin-bottom: 8px;
    }
    .error-conatiner {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      padding: 12px;
      gap: 8px;
      height: 84px;
      background: var(--error_96);
      border-radius: 12px;
      color: var(--error_24);
      margin-bottom: 12px;

      .icon-warning-col {
        color: var(--error_24);
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      .error-message {
        margin-left: 30px;
        margin-right: 20px;
        margin-top: -10px;
        width: 70%;
        font-style: normal;
        font-weight: 600;
        font-size: toRem(16px);
        line-height: 20px;
        color: var(--error_24);
      }
    }

    .color-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin-bottom: 16px;

      .color-layout {
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid rgba(var(--greyscale-custom1), 0.1);

        &.border-selected {
          border: 4px solid var(--primary_40);
        }

        &.green {
          background-color: var(--success_48);
        }

        &.yellow {
          background-color: var(--warning_64);
        }

        &.orange {
          background-color: var(--tag_orange);
        }

        &.red {
          background-color: var(--tag_red);
        }

        &.blue {
          background-color: var(--tag_blue);
        }

        &.purple {
          background-color: var(--tag_purple);
        }

        &.gray {
          background-color: var(--greyscale_56);
        }

        &.white {
          background-color: var(--brand_white);
        }

        &.black {
          background-color: var(--brand_black);
        }
      }
    }
  }
}

.vehicle-parent-container {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--grayscale_96);
  border-radius: 12px;
  padding-left: 20px;

  .vehicle-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .icon-vehicle {
        height: 20px;
        width: 20px;
      }

      .search-vehicle-container {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .search-by-vehicle {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
          display: flex;
          align-items: center;
          color: var(--brand_black);
        }
      }
    }
  }

  .expanded-container {
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    .select-more-text {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
      color: var(--greyscale_8);
      padding: 0px 20px 12px 0px;
    }
    .face-toggle {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      gap: 16px;
      background: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;

        .face-icon {
          color: var(--text_color);
        }

        .container-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .show-faces-label {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(14px);
            line-height: 20px;
            display: flex;
            align-items: center;
            color: var(--text_color);
          }

          .warning-message {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;

            .icon-info {
              color: var(--greyscale_40);
            }

            .info-message {
              margin-left: 2px;
              font-style: normal;
              font-weight: 500;
              font-size: toRem(10px);
              line-height: 16px;
              color: var(--greyscale_40);
            }
          }
        }
      }

      .right-container {
        background: var(--brand_white);
        align-items: center;
        display: flex;
        justify-content: center;

        .toggle-field-wrap {
          background: var(--brand_white);
        }
      }
    }

    .label-heading {      
      font-style: normal;
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
      margin-bottom: 4px;
    }

    .lable-show {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(12px);
      line-height: 16px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
      margin-bottom: 8px;
    }

    .error-conatiner {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      padding: 12px;
      gap: 8px;
      height: 84px;
      background: var(--error_96);
      border-radius: 12px;
      color: var(--error_24);
      margin-bottom: 12px;

      .icon-warning-col {
        color: var(--error_24);
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      .error-message {
        margin-left: 30px;
        margin-right: 20px;
        margin-top: -10px;
        width: 70%;
        font-style: normal;
        font-weight: 600;
        font-size: toRem(16px);
        line-height: 20px;
        color: var(--error_24);
      }
    }

    .vehicle-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      color: var(--greyscale_40);
      margin-top: 8px;
      gap: 8px;

      .car-layout {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        height: 40px;
        background: var(--brand_white);
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
        cursor: pointer;

        .icon-mf {
          height: 20px;
          width: 20px;
          color: var(--greyscale_40);

          &.selected {
            color: var(--primary_48);
          }
        }

        &.selected {
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          background: var(--primary_96);
        }
      }

      .suv-layout {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        height: 40px;
        background: var(--brand_white);
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
        cursor: pointer;

        &.bicyle {
          width: 50%;
        }

        &.selected {
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          background: var(--primary_96);
        }

        .icon-mf {
          height: 20px;
          width: 20px;
          color: var(--greyscale_40);

          &.selected {
            color: var(--primary_48);
          }
        }
      }

      .truck-layout {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        height: 40px;
        background: var(--brand_white);
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
        cursor: pointer;

        &.bike {
          width: 50%;
        }

        &.selected {
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          background: var(--primary_96);
        }

        .icon-mf {
          height: 20px;
          width: 20px;
          color: var(--greyscale_40);

          &.selected {
            color: var(--primary_48);
          }
        }
      }
    }

    .color-container {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;

      .color-layout {
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid rgba(var(--greyscale-custom1), 0.1);

        &.border-selected {
          border: 4px solid var(--primary_40);
        }

        &.green {
          background-color: var(--success_48);
        }

        &.yellow {
          background-color: var(--warning_64);
        }

        &.orange {
          background-color: var(--tag_orange);
        }

        &.red {
          background-color: var(--tag_red);
        }

        &.blue {
          background-color: var(--tag_blue);
        }

        &.purple {
          background-color: var(--tag_purple);
        }

        &.gray {
          background-color: var(--greyscale_56);
        }

        &.white {
          background-color: var(--brand_white);
        }

        &.black {
          background-color: var(--brand_black);
        }
      }
    }
  }
}
