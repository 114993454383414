@import '../../assets/css/base';

.search-dashboard-container {
  display: flex;
  gap: 1rem;
  width: 100%;

  .serach-box {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 18px 20px;
    width: 100%;
    height: 56px;
    background: var(--brand_white);
    border: 1.5px solid var(--greyscale_88);
    border-radius: 12px;
    align-items: center;

    &.search-modal {
      width: 100%;
      margin-top: -30px;
    }

    .textbox {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      color: var(--greyscale_40);
      margin-left: 10px;
      text-align: left;
    }
  }

  .mic-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 56px;
    width: 56px;
    background: var(--primary_40);
    border-radius: 12px;
  }
}

.events-conatiner {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .recent-events {
    padding-right: 30px;
    width: 55%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .recent-events-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .recent-events-label {
        font-style: normal;
        font-weight: 600;
        font-size: toRem(18px);
        line-height: 20px;
        color: var(--text_color);
      }

      .show-all-label {
        cursor: pointer;
        margin-right: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        color: var(--primary_40);
      }
      .recent-events-label {
        font-style: normal;
        font-weight: 600;
        font-size: toRem(18px);
        line-height: 20px;
        color: var(--text_color);
      }

      .show-all-label {
        cursor: pointer;
        margin-right: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        color: var(--primary_40);
      }
    }

    .recent-event-conatiner {
      padding-top: 20px;

      .no-event-conatiner {
        width: 100%;
        height: 130px;
        background: var(--grayscale_96);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .no-data-label {
          width: 600px;
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--body_color);
          .link {
            color: var(--primary_48);
            cursor: pointer;
            margin-right: 3px;
            margin-left: 3px;
            font-weight: 600;
          }
        }
      }

      .inner-container {
        padding: 7px;
        cursor: pointer;

        .img-event {
          width: 120px;
          height: 88px;
          object-fit: cover;
          border-radius: 8px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .suggested-event-container {
      margin-top: 20px;
      margin-bottom: 20px;

      .suggested-level {
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 16px;
        display: flex;
        align-items: center;
        color: var(--greyscale_40);
      }

      .s-container {
        width: auto;
        padding: 10px;
        margin-right: 15px;
        margin-top: 10px;
        border-radius: 10px;
        border: 1px solid var(--greyscale_80);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary_40);
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 16px;
      }
    }
  }

  .recent-event-conatiner {
    padding-top: 20px;

    .no-event-conatiner {
      width: 100%;
      height: 130px;
      background: var(--grayscale_96);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      .no-data-label {
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--body_color);
      }
    }

    .inner-container {
      padding: 7px;

      .img-event {
        width: 120px;
        height: 88px;
        object-fit: cover;
        border-radius: 8px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .suggested-event-container {
    margin-top: 20px;
    margin-bottom: 20px;

    .suggested-level {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 16px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }

    .s-container {
      width: auto;
      padding: 10px;
      margin-right: 15px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid var(--greyscale_80);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary_40);
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 16px;
    }
  }
}

.top-camera-events {
  padding-left: 30px;
  width: 45%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .top-events-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .top-events-label {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(18px);
      line-height: 20px;
      color: var(--text_color);
    }

    .show-all-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      color: var(--primary_40);
    }
  }

  .top-event-conatiner {
    padding-top: 20px;

    .no-event-conatiner {
      width: 100%;
      height: 130px;
      background: var(--grayscale_96);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      .no-data-label {
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--body_color);
      }
    }

    .today-yesterday-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px;
      width: 100%;
      height: 45px;
      background: var(--grayscale_96);
      border-radius: 12px;

      .vs-yesterday-btn {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        width: 49.5%;
        height: 35px;
        background: var(--grayscale_96);
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        color: var(--greyscale_40);

        &.selected {
          background: var(--brand_white);
          box-shadow: 0px 1px 3px rgba(var(--greyscale-custom1), 0.1),
            0px 1px 2px rgba(var(--greyscale-custom1), 0.06);
          color: var(--text_color);
        }
      }

      .vs-lastday-btn {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        width: 49.5%;
        height: 35px;
        border-radius: 8px;
        background: var(--grayscale_96);
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        color: var(--greyscale_40);

        &.selected {
          background: var(--brand_white);
          box-shadow: 0px 1px 3px rgba(var(--greyscale-custom1), 0.1),
            0px 1px 2px rgba(var(--greyscale-custom1), 0.06);
          color: var(--text_color);
        }
      }
    }

    .today-yesterday-layout {
      padding-top: 20px;

      .ty-container-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .left-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .progress-bar-events {
            width: auto;
          }

          .device-name-event-count-conatiner {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .device-name {
              font-style: normal;
              font-weight: 600;
              font-size: toRem(14px);
              line-height: 20px;
              display: flex;
              align-items: center;
              color: var(--greyscale_08);

              @media screen and (max-width: 1000px) {
                font-size: toRem(12px);
              }
            }

            .device-count-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              display: flex;
              align-items: center;

              .count-label {
                font-style: normal;
                font-weight: 200;
                font-size: toRem(14px);
                line-height: 20px;
                color: var(--greyscale_40);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (max-width: 1000px) {
                  font-size: toRem(12px);
                }
              }

              .count-color {
                margin-left: 5px;
                font-style: normal;
                font-weight: 600;
                font-size: toRem(14px);
                line-height: 20px;
                color: var(--grayscale_16);

                @media screen and (max-width: 1000px) {
                  font-size: toRem(12px);
                }
              }
            }
          }
        }

        .right-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 6px 10px;
          width: auto;
          height: 35px;
          background: var(--diff_positive);
          border-radius: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: toRem(12px);
          line-height: 16px;
          display: flex;
          align-items: center;
          color: var(--diff_text_positive);

          @media screen and (max-width: 1000px) {
            font-size: toRem(10px);
          }

          &.diff-minus {
            background: var(--error_96);
            color: var(--error_24);
          }
        }
      }
    }
  }
}

.event-preview-modal {
  .modal-dialog {
    .modal-content {
      background: transparent;
      border-radius: 16px;
      padding: 60px;
      .modal-header {
        position: absolute;
        right: 75px;
        z-index: 1;
        .site-modal-close {
          color: #fff;
          width: 44px;
          height: 44px;
          padding: 14px;
          background: rgba(var(--greyscale-custom1), 0.4);
          backdrop-filter: blur(5px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 12px;
        }
      }
      .modal-body {
        position: initial;
        padding-bottom: 0;
        .wrapper-app {
          margin-top: 0;
        }
        .events-icons-wrapper {
          position: absolute;
          z-index: 1;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          display: flex;
          top: 50%;
          margin-top: -24px;
          left: 0;
          .icons-image {
            width: 48px;
            height: 48px;
            background: var(--primary_40);
            color: #fff;
            backdrop-filter: blur(5px);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.disabled {
              pointer-events: none;
              background: #aaaaaa;
            }
          }
        }
        .search-events-modal-footer {
          color: #fff;
          margin-top: 16px;
          .modal-footer-content {
            display: flex;
            justify-content: space-between;
            .footer-modal-description {
              .footer-modal-description-location {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
              }
              .footer-modal-description-class {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                opacity: 0.6;
                margin-top: 4px;
                display: flex;
                align-items: center;
                .event-name {
                  display: inline-block;
                  margin-left: 5px;
                }
              }
            }
            .footer-modal-redirection {
              display: flex;
              min-width: 140px;
              justify-content: space-between;
              svg {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}