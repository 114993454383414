.channel-details.device-tabs {
    .section-tabs {
      .section-tab {
        width: 252px !important;
      }
    }
}

.rmdp-header {
    .rmdp-header-values {
        flex: auto;
    }
}

.channel-details.device-tabs {
    margin-bottom: 0px !important;
}

.range-field-wrap-event-time {
  background-color: white;
  border-radius: 12px;
  padding: 4px;
  gap: 8px;

  &.org-details {
    background-color: transparent;
    padding: 5px;
    pointer-events: none;
    margin-right: 10px;
  }

  &.no-devices {
    background-color: transparent;
    padding: 5px;
    pointer-events: none;
    margin-left: 15px;
    margin-right: 10px;
  }

  .range-field-label {
    margin-bottom: 8px;
  }

  .range-slider {
    min-height: 34px;
    max-width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(12px);
    line-height: 16px;

    .range-slider-track {
      top: 25px;
      height: 8px;
      background: var(--greyscale_88);
      border-radius: 12px;

      &.range-slider-track-0 {
        /* color of the track before the thumb */
        background: var(--greyscale_88);
      }

      &.range-slider-track-1 {
        /* color of the track before the thumb */
        background: var(--primary_40);
      }

      &.range-slider-track-2 {
        /* color of the track before the thumb */
        background: var(--greyscale_88);
      }

      .range-slider-start-value {
        position: absolute;
        bottom: 18px;
      }

      .range-slider-last-value {
        position: absolute;
        right: 0;
        bottom: 18px;
      }
    }

    .org-range-slider-track {
      top: 25px;
      height: 8px;
      background: var(--progress_org_device);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-left: -10px;

      &.org-range-slider-track-0 {
        /* color of the track before the thumb */
        background: var(--primary_40);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 10px;
      }

      .range-slider-start-value {
        position: absolute;
        bottom: 18px;
      }

      .range-slider-last-value {
        position: absolute;
        right: 0;
        bottom: 18px;
      }
    }

    .range-slider-thumb {
      top: 15px;
      width: 24px;
      height: 24px;
      background: var(--primary_40);
      border: 8px solid var(--brand_white);
      border-radius: 50%;
      box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.06),
        0px 2px 4px -1px rgba(var(--greyscale-custom1), 0.04);

      &:focus-visible {
        outline: unset;
      }

      &.range-slider-thumb-0 {
        background: var(--brand_white) !important;
        border: 8px solid var(--primary_40) !important;
      }

      &.range-slider-thumb-1 {
        background: var(--brand_white) !important;
        border: 8px solid var(--primary_40) !important;
      }

      .range-slider-thumb-value {
        position: absolute;
        bottom: 18px;
        left: -5px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: 16px;
        color: var(--primary_40);
      }
    }
  }
    .custom-radius {
      .org-range-slider-track{
        margin-left: 1px;
    }
    }
}