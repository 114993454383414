@import '../../../assets/css/utilities';
@import '../../../assets/css/base';

.pos-integration {
  width: 100%;

  .steps-header-conatiner {
    width: 100%;

    .header-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-section-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .back-btn-conatiner {
          cursor: pointer;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .back-text {
            cursor: pointer;
            margin-left: 5px;
            color: var(--primary_40);
            font-size: toRem(16px);
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .header-title {
          color: var(--brand_black);
          font-size: toRem(18px);
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .cross-btn {
        width: 24px;
        height: 24px;
      }
    }
  }

  .pos-step-indicator-conatiner {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .step-indicator {
      border-radius: 16px;
      background: var(--primary_88);
      height: 4px;
      width: 40px;

      &.margin-other {
        margin-left: 8px;
      }

      &.selected {
        background: var(--primary_40);
      }
    }
  }

  .step-one-to-three {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .text-content {
      color: var(--primary_05);
      font-size: toRem(12px);
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .step-one-upload-file-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .lable-upload-file {
      color: var(--brand_black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: start;
    }

    .site-spinner-download {
      display: inline-block;
      top: 30%;
      left: 40%;
      position: absolute;
      width: 85px;
      height: 85px;
      padding: 5px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: var(--brand_white);
      --_m: conic-gradient(#0000 2%, #000), linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: s3 1s infinite linear;
      vertical-align: middle;
    }

    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }

    .extract-message {
      color: var(--greyscale_40);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      font-family: "Noto Sans";
    }

        .check-row-header-container {
          margin-left: 1px;
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .img-unselected{
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
          .img-selected{
            border-radius: 4px;
            color: var(--primary_40);
            cursor: pointer;
          }
      .file-have-header {
        margin-left: 8px;
        margin-top: 1px;
        color: var(--greyscale_40);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }


    }
  }

  .step-two-posdata-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;

    .lable-upload-file {
      color: var(--brand_black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: start;
    }

    .extract-message {
      color: var(--greyscale_40);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      font-family: "Noto Sans";
    }

    .selected-column-container {
      margin-top: 20px;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .selected-col-count {
        color: var(--brand_primary);
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        font-family: "Noto Sans";
      }

      .all-col-count {
        color: var(--greyscale_40);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        font-family: "Noto Sans";
      }
    }

    .list-container {
      padding: 14px 20px;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12px;
      background: var(--grayscale_96);
      justify-content: space-between;

      .left-layout-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 92%;

        .col-name-heading {
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        .sub-content-label {
          width: 90%;
          color: var(--greyscale_40);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .right-layout-col {
        width: 8%;
        align-items: center;

        .img-unselected{
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
        .img-selected{
          border-radius: 4px;
          color: var(--primary_40);
          cursor: pointer;
        }
      }
    }



  }

  .step-three-Attributes {
    padding-bottom: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.edit-modal-mapping {
      margin-top: 24px;
    }

    .lable-upload-file {
      color: var(--brand_black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: start;
    }

    .extract-message {
      color: var(--greyscale_40);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      font-family: "Noto Sans";
    }

    .list-container {
      padding: 14px 20px;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12px;
      background: var(--grayscale_96);
      justify-content: space-between;

      &.date-time-selected {
        align-items: flex-start;

      }

      .left-layout-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;

        &.date-time-selected {
          margin-top: 5px;

        }

        .col-name-heading {
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        .sub-content-label {
          width: 200px;
          color: var(--greyscale_40);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 4px;
        }
      }

      .right-layout-col {
        width: 60%;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .selection-wrapper {
          display: flex;
          align-items: flex-end;
          gap: 12px;
          flex-shrink: 0;
          min-width: 100%;
          margin-bottom: 2px;
          margin-top: 2px;

        }

                .textbox-pos {
                  margin-top: 4px;
                  width: 100%;
                  font-style: normal;
                  font-weight: 500;
                  font-size: toRem(16px);
                  line-height: 20px;
                  color: var(--greyscale_40);
                  text-align: left;
                  border: 1.5px solid var(--greyscale_88);
                  border-radius: 12px;
                  position: relative;
               
                }
                .asterisk {
                  position: absolute;
                  top: 5px;
                  left: 60%; /* Adjust this value as needed */
                  color: red;
                }
                

        .area-selector {
          width: 100%;

          .text-selected {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 150px;
            margin-left: 0px !important;
            color: var(--text_color);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

          }

          &-toggle {
            &.dropdown-toggle {
              display: flex;
              padding: 8px 15px;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
              width: 100%;
              font-size: toRem(14px);
              font-weight: 500;
              line-height: 20px;
              border: 1.5px solid var(--greyscale_88);
              background: var(--brand_white);
              color: var(--primary_04);
              border-radius: 12px;

              &.btn-outline-secondary {
                &.show {
                  background: var(--brand_white);
                  color: var(--primary_04);
                  border-radius: 10px;
                  border: 1.5px solid var(--greyscale_88);
                }
              }

              &::after {
                display: none !important;
              }

              &:hover {
                border: 1.5px solid var(--greyscale_88);
                background: var(--brand_white);
                color: var(--primary_04);
              }
            }
          }

          &-menu {
            &.dropdown-menu {
              width: 100%;
              height: 250px;
              overflow: auto;
              background: var(--brand_white);

              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                  0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 12px;
              }
            }

            &-item {
              &.dropdown-item {
                width: 100%;
                font-size: toRem(14px);
                font-weight: 500;
                line-height: 20px;
                border: none;
                background: var(--brand_white);
                color: var(--primary_04);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.active {
                  background: var(--brand_white);
                  color: var(--primary_04);
                  font-weight: 700;
                }

                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }

                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }
              }

              &.dropdown-item:hover {
                background: var(--primary_02);
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }
            }
          }

          .area-selected {
            &-wrapper {
              display: inline-flex;
              align-items: center;
            }

            &-name {
              margin-left: 10px;
            }
          }
        }

        .bottom-strip-container {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &.custom-attribute {
            justify-content: end;

          }

          .date-formate-info {
            color: var(--greyscale_40);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .formate-label-text {
              margin-left: 5px;
              color: var(--greyscale_40);
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }
          }

          .value-out-of-label {
            color: var(--greyscale_40);
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }

          .custom-attibute-value-length {
            color: var(--greyscale_40);
            text-align: right;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }

      }
    }

  }


  .step-four-unique-identifier {
    padding-bottom: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .lable-upload-file {
      color: var(--brand_black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: start;
    }

    .extract-message {
      color: var(--greyscale_40);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      font-family: "Noto Sans";
    }

    .list-unique-identifier {
      padding: 14px 20px;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12px;
      background: var(--grayscale_96);
      justify-content: space-between;

      .left-layout-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 40%;

        &.date-time-selected {
          margin-top: 5px;

        }

        .col-name-heading {
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          font-family: "Noto Sans";

        }

        .sub-content-label {
          width: 150px;
          color: var(--greyscale_40);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 4px;
          font-family: "Noto Sans";

        }
      }

      .middle-layout {
        width: 40%;

        .unique-text {
          color: var(--text_color);
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 200px;
          font-family: "Noto Sans";
        }
      }

      .right-layout-col {
        width: 10%;
        align-items: end;
        margin-top: 20px;
        margin-bottom: 20px;

        .img-unselected{
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
        .img-selected{
          border-radius: 4px;
          color: var(--primary_40);
          cursor: pointer;
        }
      }

    }
  }

  .step-five-locations {
    padding-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .lable-upload-file {
      color: var(--brand_black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: start;
    }

       .extract-message {
      color: var(--greyscale_40);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      font-family: "Noto Sans";
    }

    .main-container-store-loc {
      border-radius: 12px;
      background: var(--grayscale_96);
      padding: 14px 20px;

      .store-location-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        .remove-area-icon {
          stroke: var(--error_64);
          margin-left: 10px;
  
        }
  
        .input-type-box-container {
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          margin-right: 10px;
          height: 40px;
          margin-top: 5px;
        }
  
        .selection-wrapper {
          display: flex;
          align-items: flex-end;
          gap: 12px;
          flex-shrink: 0;
          margin-bottom: 2px;
          margin-top: 2px;
  
        }
  
        .area-selector {
          width: 100%;
          height: 42px;
  
          .text-selected {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 150px;
            margin-left: 0px !important;
            color: var(--text_color);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
  
          }
  
          &-toggle {
            &.dropdown-toggle {
              display: flex;
              padding: 8px 15px;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
              width: 100%;
              font-size: toRem(14px);
              font-weight: 500;
              line-height: 20px;
              border: 1.5px solid var(--greyscale_88);
              background: var(--brand_white);
              color: var(--primary_04);
              border-radius: 12px;
  
              &.btn-outline-secondary {
                &.show {
                  background: var(--brand_white);
                  color: var(--primary_04);
                  border-radius: 10px;
                  border: 1.5px solid var(--greyscale_88);
                }
              }
  
              &::after {
                display: none !important;
              }
  
              &:hover {
                border: 1.5px solid var(--greyscale_88);
                background: var(--brand_white);
                color: var(--primary_04);
              }
            }
          }
  
          &-menu {
            &.dropdown-menu {
              width: 100%;
              height: auto;
              overflow: auto;
              background: var(--brand_white);
  
              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                  0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 12px;
              }
            }
  
            &-item {
              &.dropdown-item {
                width: 100%;
                font-size: toRem(14px);
                font-weight: 500;
                line-height: 20px;
                border: none;
                background: var(--brand_white);
                color: var(--primary_04);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
  
                &.active {
                  background: var(--brand_white);
                  color: var(--primary_04);
                  font-weight: 700;
                }
  
                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }
  
                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }
              }
  
              &.dropdown-item:hover {
                background: var(--primary_02);
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }
            }
          }
          &-minList {
            &.dropdown-menu {
              width: 100%;
              height: auto;
              overflow: auto;
              background: var(--brand_white);
  
              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                  0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 12px;
              }
            }
             &.dropdown-menu {
              width: 100%;
              height: auto;
              overflow: auto;
              background: var(--brand_white);
  
              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                  0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 12px;
              }
            }
  
            &-item {
              &.dropdown-item {
                width: 100%;
                font-size: toRem(14px);
                font-weight: 500;
                line-height: 20px;
                border: none;
                background: var(--brand_white);
                color: var(--primary_04);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
  
                &.active {
                  background: var(--brand_white);
                  color: var(--primary_04);
                  font-weight: 700;
                }
  
                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }
  
                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }
              }
  
              &.dropdown-item:hover {
                background: var(--primary_02);
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }
            }
          }
  
          .area-selected {
            &-wrapper {
              display: inline-flex;
              align-items: center;
            }
  
            &-name {
              margin-left: 10px;
            }
          }
        }
  
        .create-area-icon {
          stroke: var(--primary_40);
          margin-left: 10px;
  
        }
  
      }
    }

    
  }

  .org-map-setup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.modal-succ-failure {
      margin-top: 24px;
    }

    .delete-view-loader {
      background: var(--error_64);
    }
    
    .title-setup-completed {
      font-family: "Noto Sans";
      color: var(--text_color);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 24px;
    }

    .btn-margin-try {
      margin-top: 25px;
    }

  }

  .warning-upload-layout{
    .image-icon {
        width: 96px;
        height: 96px;
    }

        .text-message-warning {
          align-items: center;
          color: var(--brand_black);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
  }

  .setupPos {
    background-color: var(--brand_primary);
    color: var(--brand_white);
    border-color: var(--brand_primary);
    border-width: 2px;
    border-radius: 12px;
    min-height: 44px;
    height: 44px;
    width: 100%;
    border-style: solid;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background-color: var(--primary_24);
      border-color: var(--primary_24);

    }

  }
}