@import '../../assets/css/base.scss';

.toast-wrapper-incident {
  max-width: 400px !important;
  position: absolute;
  background-color: var(--brand_white);
  top: 0;
  right: 0;
}

.incident-details {
  .page {
    &-header {
      margin-bottom: 0 !important;

      .page {
        &-title {
          padding-left: 0;
        }

        &-incident-id {
          color: var(--greyscale_56);

          /* Subheading/16px */
          font-family: Noto Sans;
          font-size: toRem(16px);
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }

      .close-incident-button {
        color: var(--error_64);
        border-color: var(--error_64);
        border-radius: 10px;
        gap: 8px;
        margin-right: 8px;
        padding: 10px 16px;
        font-size: toRem(14px);
        font-weight: 600;
        line-height: toRem(20px);

        &:focus {
          border-color: var(--primary_24);
        }

        &:hover {
          border-color: var(--error_64);
          background-color: var(--error_96);
        }

        &-label {
        }
      }

      .save-changes-button {
        border-radius: 10px;
        padding: 10px 16px;
        font-size: toRem(14px);
        font-weight: 600;
        line-height: toRem(20px);

        &:focus {
          border-color: var(--primary_24);
        }

        &:hover {
          background-color: var(--primary_24);
        }

        &.disabled {
          border-color: var(--greyscale_88);
          background-color: var(--greyscale_88);
          color: var(--grayscale_32);
        }
      }
    }

    &-body {
      .incident-container {
        padding: 0;
      }
    }
  }

  &-text-block {
    color: var(--greyscale_08);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-form-label {
    text-align: left;
    color: var(--greyscale_08);
    font-size: toRem(20px);
    font-weight: 600;
    line-height: toRem(28px);
  }

  &-short-summary {
    color: var(--greyscale_08);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-status,
  &-tags {
    &-detail {
      color: var(--greyscale_40);
      font-size: toRem(12px);
      font-weight: 500;
      line-height: toRem(20px);
    }

    &-wrapper {
      display: block;
      text-align: left;
    }
  }

  &-label-action {
    color: var(--primary_40);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
    cursor: pointer;
    margin-top: 2px;
  }

  &-tags {
    &-pill {
      display: flex;
      padding: 8px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 16px;
      color: var(--brand_white);
      background-color: var(--primary_40);
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
      margin-right: 8px;
      margin-bottom: 8px;
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      flex-wrap: wrap;
    }
  }

  &-date-container {
    width: 100%;

    .icon-tabler-calendar-event {
      margin-right: 10px;
      color: var(--greyscale_40);
      height: 20px;
    }

    .customized-datepicker {
      .rmdp-day-picker,
      .rmdp-month-picker,
      .rmdp-year-picker {
        .rmdp-day {
          span:hover {
            background-color: var(--primary_64);
          }

          &.rmdp-today {
            span {
              background-color: var(--primary_80);
              color: var(--text_color);
            }
          }

          &.rmdp-disabled {
            span:hover {
              background-color: var(--primary_64);
              color: var(--brand_white);
            }
          }

          &.rmdp-selected {
            span:not(.highlight) {
              background-color: var(--primary_40);
            }
          }
        }
      }
    }
  }

  &-time-container {
    .MuiTextField-root {
      width: 100%;
      border-color: var(--grayscale_96);

      .MuiOutlinedInput-root {
        border-radius: 10px;

        &.MuiInputBase-colorPrimary.MuiInputBase-formControl {
          &:hover {
            border-color: var(--primary_88);
          }

          &:focus {
            border-color: var(--primary_40);
          }
        }

        .MuiOutlinedInput-input {
          padding: 16.5px 16px;
        }

        .MuiSvgIcon-root {
          height: 20px;
          color: var(--greyscale_40);
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--primary_40);
            border-width: 1px;
          }
        }
      }
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary_40);
      }
      
    }
  }
    .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary_40);
    }
  &-input {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    width: 100%;

    &-shaded {
      border-color: var(--grayscale_96);
      border-radius: 10px;
      background: var(--grayscale_96);
    }
  }

  &-cal-input {
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    margin-right: 20px;
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding-left: 16px;

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_40);
      outline: 0;
    }
  }

  &-location-list {
  }

  &-locations {
    .base-title {
      text-align: left;
    }
  }

  .incidents-table-container {
    .incident-tabs {
      .section-tab {
        width: 100% !important;
      }
    }
  }

  .no-incidents-container {
    background-color: var(--grayscale_96);
    margin: 54px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 192px;
    padding: 44px 0;
    gap: 8px;

    .folder-icon {
      color: var(--greyscale_08);
      font-weight: 400;
      flex-shrink: 0;
      margin-top: 14px;
    }

    .no-incident-alert {
      color: var(--greyscale_56);
      margin-top: 10px;
      font-size: toRem(16px);
      font-weight: 500;
    }
  }

  .timezone-input-wrapper {
    text-align: left;
  }
}

.edit-tags-modal,
.close-incident-modal,
.reopen-incident-modal,
.pending-changes-confirmation-modal {
  .modal {
    &-dialog {
      width: 516px;

      .modal {
        &-header {
          padding-top: 0px;
          padding-bottom: 4px;
        }

        &-body {
          .reopen-incident-description {
            color: var(--greyscale_40);
            font-size: toRem(16px);
            font-style: normal;
            font-weight: 400;
            line-height: toRem(24px);
          }
        }

        &-footer {
          padding-left: 0;
          padding-right: 0;
          border: 0;

          .edit-tags,
          .close-incident,
          .reopen-incident,
          .pending-changes {
            &-button-group {
              width: 100%;
            }

            &-button-wrapper {
              width: 100%;
              margin-top: 8px;
              margin-left: 0;
              margin-right: 0;
              margin-bottom: 0px;
            }

            &-save-btn {
              font-size: toRem(14px);
              line-height: 20px;
              font-weight: 600;
              height: 44px;

              &:hover {
                background-color: var(--primary_24);
              }

              &.disabled {
                color: var(--grayscale_32);
              }
            }

            &-cancel-btn.btn.btn-outline-secondary {
              font-size: toRem(14px);
              line-height: 20px;
              font-weight: 600;
              width: 100%;
              height: 44px;
              color: var(--primary_40);
              border-color: var(--primary_40);

              &:focus {
                border-color: var(--primary_40);
                background-color: var(--brand_white);
              }

              &:hover {
                background-color: var(--primary_96);
              }
            }
          }
        }
      }
    }
  }
}

.MuiPickersPopper-root.MuiPopper-root {
  .MuiPaper-root {
    border-radius: 12px;

    box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
      0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);

    .MuiPickersLayout-contentWrapper {
      display: flex;
      justify-content: space-around;
    }

    .MuiPickersLayout-root {
      .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: var(--primary_96);
        color: var(--primary_40);

        &:hover {
          background-color: var(--primary_88);
        }
      }
    }
  }
}
