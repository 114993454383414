@import '../../assets/css/base.scss';

.share {
  &-video-clip-modal {
    .modal-header {
      padding-top: 0;
    }
  }

  &-container {
    width: 100%;
    min-height: 300px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &-invite {
    &-form {
      // position: static;

      &-alert {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--warning_96);
        margin-bottom: 24px;

        &-icon {
          .icon-alert {
            color: var(--warning_24);
          }
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        &-title {
          color: var(--warning_24);
          font-size: toRem(16px);
          font-weight: 600;
          line-height: toRem(20px);
          text-align: left;
          align-self: stretch;
          margin-top: 4px;
        }

        &-message {
          color: var(--warning_24);
          font-size: toRem(14px);
          font-weight: 400;
          line-height: toRem(20px);
          align-self: stretch;
          margin-top: 4px;
        }
      }

      &-error {
        color: var(--error_48);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);
        margin: 9px 0 24px 20px;
      }

      &-note {
        color: var(--greyscale_56);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);
        margin: 9px 0 24px 20px;
      }

      &-section {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 600;
        line-height: normal;
      }

      .share-invite-email {
        &-field {
          margin: 0;
          // height: 100%;

          &-chip {
            display: flex;
            align-items: center;
            gap: 6px;
            border-radius: 10px;
            background-color: var(--primary_96);
            color: var(--primary_32);
            font-size: toRem(12px);
            font-weight: 600;
            line-height: toRem(16px);
            height: 28px;
            margin-right: 4px;

            &-delete-icon {
              margin-right: 6px !important;
              color: var(--primary_32);
              cursor: pointer;
            }

            .MuiChip-label {
              padding-right: 4px;
            }
          }
        }

        &-input {
          border-radius: 12px;

          .MuiInputBase-root.MuiOutlinedInput-root {
            border-radius: 12px;

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              border: 0;

              fieldset {
                outline: 0;
                border: 1.5px solid var(--primary_40);
                padding: 0;
              }
            }
          }

          .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
            border-radius: 12px;
            .MuiAutocomplete-tag {
              display: flex;
              justify-content: space-around;
              align-items: center;
              border-radius: 50%;
              color: var(--primary_40);
              background-color: var(--primary_96);
              font-size: toRem(14px);
              width: 32px;
              height: 28px;
              padding: 2px;
            }
          }
        }
      }

      .MuiAutocomplete-root.Mui-focused {
        fieldset {
          border-color: var(--primary_40);
        }
      }
    }

    &-email {
      &-icon {
        margin-left: 10px;
        color: var(--greyscale_56);
      }

      &-input {
        height: 100%;
      }

      &-field {
      }
    }

    &-divider {
      background-color: var(--hGreyscale_88);
      height: 2px;
    }
  }

  &-subscribers {
    overflow-y: visible;
    max-height: 168px;

    .share-subscriber {
      padding-left: 0;
      border-bottom: 1px solid var(--greyscale_88);
      height: 42px;

      &-name {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 400;
        line-height: toRem(20px);
        flex: none;
        margin-right: 8px;

        .subscriber-contact {
          max-width: 328px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &-type {
        display: flex;
        height: 18px;
        padding: 2px 0px;
        align-items: center;
        gap: 4px;
        border-radius: 235px;
        font-size: toRem(12px);
        font-weight: 400;
        line-height: toRem(16px);

        &.owner {
          color: var(--primary_40);
          background-color: var(--primary_96);
        }

        &.internal {
        }

        &.external {
          color: var(--warning_24);
          background-color: var(--warning_80);
        }

        & > .MuiChip-label {
          padding: 2px 8px;
        }
      }

      &-status {
        color: #7d8ba1;
        font-size: toRem(16px);
        font-weight: 400;
        line-height: toRem(20px);
        margin-right: 6px;
      }

      &-menu-placeholder {
        display: inline-flex;
        align-self: center;
        width: 8px;
        height: 10px;
      }

      .MuiListItemSecondaryAction-root {
        margin-right: -6px;
        height: 42px;
        display: flex;
        align-items: center;

        .MuiButtonBase-root.MuiIconButton-root {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

#menu-share-subscriber-menu {
  .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPopover-paper:has(
      .context-menu-list
    ):has(.MuiButtonBase-root.MuiMenuItem-root) {
    &:has(.share-subscriber-menu-item-option) {
      margin-top: -35px;
      margin-left: -196px;
    }

    &:has(.comment-item-text) {
      margin-top: -36px;
      margin-left: -100px;
    }

    .menu-item-expandable-list-item {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0;

      &:first-child {
        padding-top: 4px;
      }

      &-icon {
        min-width: 26px;
      }

      &-text span {
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: toRem(20px);
      }
    }
  }
}

.share-video-clip-modal {
  .modal-body {
    .MuiAutocomplete-root {
    }
  }
}

#share-invite-combo-box-listbox {
  border-radius: 12px;
  border: 1.5px solid var(--grayscale_96);
  background: var(--brand_white);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  height: 125px;

  li.MuiAutocomplete-option {
    color: var(--brand_black);

    &[aria-selected='true'] {
      background-color: var(--primary_96);
    }
  }
}
