@import '../../assets/css/base';

.apexcharts-canvas {
  background: linear-gradient(
    180deg,
    var(--megatron-flag1) 0%,
    var(--megatron-flag2) 100%
  ) !important;
  .apexcharts-grid {
    rect {
      fill: none;
    }
  }
}

.no-content {
  height: 200px;
  align-items: center;
}

.filter-group {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: 350px;

  &.camera-uptime {
    justify-content: flex-end;
    position: absolute;
    z-index: 1000;
  }
  .view-all-analytics {
    font-weight: 600;
    font-style: normal;
    color: var(--primary_40);
    justify-content: flex-end;
    font-size: toRem(14px);
    cursor: pointer;
  }
}
