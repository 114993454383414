.audio-settings-block {
    &.editable-off-offline {
        pointer-events: none;
    }

    .settings-block {
        &.audio-in-title {
            .settings-block-title {
                margin-top: 0;
            }
        }

        .input-with-icons {
            .icon-before-input {
                height: 44px;
            }
        }
    }
}