@import '../../assets/css/base';

.analytics {
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;

  .main-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .analytics-header {
      display: flex;
      background-color: var(--brand_white);
      align-items: center;
      height: 80px;

      .toast-wrapper,
      .header-wrapper {
        display: inline-flex;
        justify-content: space-between;
        text-align: left;
        width: 100vw;
      }

      .header-wrapper {
        padding-left: 0;
        padding-right: 0;

        .page-title {
          color: var(--brand_black);
          font-size: toRem(24px);
          font-weight: 700;
          padding-left: 0;
        }
      }
    }

    .analytics-background {
      display: flex;
      flex-direction: column;
      background-color: var(--grayscale_96);
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 30px;

      .icon-cross-btn {
        cursor: pointer;
        top: 10px;
        position: absolute;
        right: 20px;
      }

      .analytics-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--grayscale_96);
        margin-top: 24px;
        // padding: 16px 30px;

        .analytics-row {
          //   padding: auto 30px;
          margin-bottom: 30px;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
          &.user-login {
            padding-bottom: 50px;
          }
        }
      }
    }
  }
}
