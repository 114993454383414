@import '../../../assets//css//base.scss';

.status {
  &-field {
    display: inline-flex;
    align-items: center;
    color: var(--greyscale_08);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-indicator {
    margin-right: 0.375rem;
    height: 0.625rem !important;
    width: 0.625rem !important;

    &.status {
      &-open {
        color: var(--success_48);
      }

      &-reopened {
        color: var(--success_48);
      }

      &-incomplete {
        color: var(--error_48);
      }

      &-closed,
      &-closed-resolved,
      &-closed-unresolved {
        color: var(--greyscale_40);
      }
    }
  }
}
