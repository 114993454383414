@import '../../assets/css/base';

.modal-dialog.modal-fullscreen {
  .modal-content {
    border-radius: 0px;
    background: content-box;
  }
}

.search-label-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .explore-text {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--greyscale_08);
  }

  .supported-lang {
    font-style: italic;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--greyscale_08);
    opacity: 0.6;
  }
}

.try-saying-something {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);
  opacity: 0.6;
}

.search-container-history {
  margin-top: 20px;
  background-color: var(--brand_white);
  border-radius: 8px;

  .search-history-label {
    width: 90%;
    font-style: normal;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--greyscale_40);
    margin-left: 12px;
    margin-left: 12px;
    padding-top: 15px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 18px;
  }   
  
  .search-list-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;

    .search-history-text {
      width: 90%;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(20px);
      line-height: 28px;
      color: var(--greyscale_40);
    } 
  }
}

.voice-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .outer-view {
    width: 95px;
    height: 95px;
    position: fixed;
    bottom: 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: transparent;

    &.voice-listening {
      background: var(--primary_96);
      animation: mymove 1s infinite;
    }

    @keyframes mymove {
      from {
        background-color: var(--primary_88);
      }

      to {
        background-color: var(--primary_96);
      }
    }

    .voice-recognition-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px;
      gap: 8px;
      width: 64px;
      height: 64px;
      background: var(--primary_40);
      border-radius: 64px;
      position: fixed;
      bottom: 25px;
    }
  }
}

.serach-box {
  height: 76px !important;
  .search-label-style {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 600;
    margin-top: 3px;
    margin-left: -8px;
    height: 20px !important;
  }
  .btn-primary {
    width: 114px !important;
    margin-top: -33px;
    margin-right: -11px;
    float: inline-end;
  }
  .span {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 540px) {
  .serach-box .btn-primary {
    width: 114px !important;
    margin-top: -33px;
    margin-right: -11px;
    float: inline-start;
    min-height: 38px !important;
    margin-top: 5px;
  }
  
  .serach-box {
    height: 122px !important;
  }
}


.tag-in-search-duclo {
  margin-top: -32px !important;
  margin-left: 104px !important;
}

.tag-in-search-oncloud {
  margin-top: -32px !important;
  margin-left: 115px !important;
}

.bg-color-search-pop-up {
  opacity: var(--bs-backdrop-opacity) !important;
}

@media screen and (max-width: 3000px) {
  .input-style-event {
    height: 26px !important;
    width: 83% !important;
  }
}

@media screen and (max-width: 1000px) {
  .input-style-event {
    height: 26px !important;
    width: 64% !important;
  }
}

@media screen and (max-width: 800px) {
  .input-style-event {
    height: 26px !important;
    width: 60% !important;
  }
}

@media screen and (max-width: 800px) {
  .input-style-event {
    height: 26px !important;
    width: 60% !important;
  }
}


.icon-sparkles {
  padding-right: 6px !important;
}

